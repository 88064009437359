import React from 'react';
import { st, classes } from './UserBox.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export interface IUserBoxProps {
  imageUrl: string;
  fullName: string;
  size?: string;
  alignment?: any;
  dataHook?: string;
  className?: string;
}

const getInitials = (name: string): string => {
  if (!name) {
    return '';
  }

  return name.split(' ')[0].substr(0, 1);
};

const UserBoxComponent: React.FC<IUserBoxProps> = (props) => {
  const { imageUrl, fullName, alignment, dataHook, className } = props;
  const { isMobile } = useEnvironment();

  return (
    <div
      className={st(classes.root, { mobile: isMobile, alignment }, className)}
      data-hook={dataHook}
    >
      <figure className={classes.pic} aria-label="Challenge owner name">
        {imageUrl ? (
          <img src={imageUrl} alt={fullName} className={classes.picMedia} />
        ) : (
          <figcaption className={classes.initials} aria-label={fullName}>
            {getInitials(fullName)}
          </figcaption>
        )}
      </figure>
      <div className={classes.info}>
        <span className={classes.name}>{fullName}</span>
      </div>
    </div>
  );
};

export default UserBoxComponent;
