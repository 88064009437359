import React from 'react';

import {
  V1Challenge,
  V1ParticipantStep,
} from '@wix/ambassador-challenge-service-web/types';

import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import { isSelfPaced } from '../../../../../selectors/challenges';
import { GeneralDataContext } from '../../../../../contexts/GeneralDataProvider/GeneralDataContext';
import { Challenges } from '../../../../../editor/types/Experiments';

import { ChallengeStats } from '../ChallengeStats';
import { RewardInfo } from '../../../../../components-shared/RewardInfo';
import { SocialGroup } from '../../../../../components-shared/SocialGroup';
import { ProgressBar } from '../../../../../components-shared/ProgressBar';
import { ChallengeDots } from '../ChallengeDots';

import { classes, st } from './ChallengeHeader.st.css';
import utils from '../../views/utils';
import { ChallengeNotification } from '../ChallengeNotification';
import { IChallengeJoinRestriction } from '../../interfaces';
import { ListLayoutButton } from '../Buttons/ListLayoutButton/ListLayoutButton';

export interface IChallengeHeaderProps {
  className?: string;
  challenge: V1Challenge;
  isParticipantCompletedChallenge: boolean;
  flatStepsList: V1ParticipantStep[];
  isGroupsInstalled: boolean;
  tabs: React.ReactElement;
  onLeave: Function;
}

// !! Only For List Layout, Participant View

export const ChallengeHeader: React.FunctionComponent<IChallengeHeaderProps> =
  ({
    className = '',
    challenge,
    isParticipantCompletedChallenge,
    flatStepsList,
    isGroupsInstalled,
    tabs,
    onLeave,
  }) => {
    const { t } = useTranslation();
    const { isMobile } = useEnvironment();
    const settings = useSettings();
    const alignment = utils.getHeaderAlignByType('List', settings);
    const { instance } = React.useContext(GeneralDataContext);
    const { experiments } = useExperiments();

    const isSPC = isSelfPaced(challenge);
    const resolvedValue = isSPC
      ? utils.getStepsResolvedValue(flatStepsList)
      : null;
    const isHappyScreenExpEnabled = experiments.enabled(
      Challenges.enableHappyScreens,
    );

    return (
      <>
        <header
          className={`${st(classes.root, {
            mobile: isMobile,
            alignment,
          })} ${className}`}
        >
          <div className={classes.headerInner}>
            {isHappyScreenExpEnabled && isParticipantCompletedChallenge ? (
              <p className={classes.completed}>
                {t('challenge.page.completed')}
              </p>
            ) : null}

            <h1
              className={classes.headerTitle}
              data-hook="challenge-page-title"
            >
              {(challenge as any)?.shouldTranslateTitle
                ? t(challenge.settings.description.title)
                : challenge.settings.description.title}
            </h1>

            <ChallengeStats type="List" challenge={challenge} />

            <RewardInfo
              className={classes.headerRewards}
              rewards={challenge.settings.rewards}
              icon={true}
            />

            <SocialGroup
              Button={ListLayoutButton}
              isGroupInstalled={isGroupsInstalled}
              className={classes.groupTitle}
              instance={instance}
              groupId={challenge?.settings?.socialGroupId}
              mode="compact"
            />

            {isSPC ? (
              <ProgressBar
                className={classes.progress}
                align={settings.get(
                  challengeSettings.sidebarLayoutTextAlignment,
                )}
                min={0}
                max={100}
                label={`${resolvedValue}%`}
                value={resolvedValue}
                aria-describedby={`${resolvedValue}%`}
              />
            ) : null}

            <ChallengeDots type="List" onLeave={onLeave} />
          </div>

          {tabs}
        </header>

        {isParticipantCompletedChallenge ? (
          <ChallengeNotification
            challengeJoinRestrictions={[IChallengeJoinRestriction.FINISHED]}
            type="ListInner"
          />
        ) : null}
      </>
    );
  };
ChallengeHeader.displayName = 'ChallengeHeader';
