import React from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { isSelfPaced } from '../../../../../selectors/challenges';
import { useChallengeData } from '../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import format from 'date-fns/format';
import useLocales from '../../../../../services/hooks/useLocales';

import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { TextButton } from 'wix-ui-tpa/TextButton';

import { classes, st } from './StepViewHidden.st.css';
import utils from '../../views/utils';

export interface IStepViewHiddenProps {
  className?: string;
  type: 'Sidebar' | 'List';
  date: string;
  titleKey?: string;
  descriptionKey?: string;
  isWeekFixForCTA?: boolean;
  goToCurrentStep(e): void;
  'data-hook'?: string;
}

export const StepViewHidden: React.FunctionComponent<IStepViewHiddenProps> = (
  props,
) => {
  const {
    className,
    type,
    date,
    isWeekFixForCTA,
    goToCurrentStep,
    titleKey,
    descriptionKey,
  } = props;
  const { t } = useTranslation();
  const { isMobile, language } = useEnvironment();
  const { challengeData } = useChallengeData();
  const isSPC = isSelfPaced(challengeData?.challenge);
  const settings = useSettings();
  const alignment = utils.getContentAlignByType(type, settings);
  const { locales, isLocalesLoading } = useLocales();

  if (isLocalesLoading) {
    return null;
  }

  const dateText = format(new Date(date), 'EEEE, PP', {
    locale: locales[language],
  });
  const titleText = isSPC
    ? t(titleKey || 'live.challenges-page.hidden-step.title-spc')
    : t('live.challenges-page.hidden-step.title-scheduled');

  const descriptionText =
    isSPC && !descriptionKey
      ? t('live.challenges-page.hidden-step.description-spc')
      : t(
          descriptionKey ||
            'live.challenges-page.hidden-step.description-scheduled',
          {
            date: dateText,
          },
        );

  return (
    <div
      className={st(
        classes.root,
        {
          mobile: isMobile,
          type: type.toLowerCase(),
          alignment,
        },
        className,
      )}
      data-hook={props['data-hook']}
    >
      <Text
        tagName="h3"
        typography={TYPOGRAPHY.largeTitle}
        className={classes.title}
      >
        {titleText}
      </Text>

      <Text
        tagName="p"
        typography={TYPOGRAPHY.runningText}
        className={classes.description}
      >
        {descriptionText}
      </Text>

      <TextButton className={classes.ctaLink} onClick={goToCurrentStep}>
        {!isWeekFixForCTA
          ? t('live.challenges-page.hidden-step.cta')
          : t('live.challenges-page.hidden-step.cta-week')}
      </TextButton>
    </div>
  );
};
StepViewHidden.displayName = 'StepViewHidden';
