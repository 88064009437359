import { pluginVideo } from 'wix-rich-content-plugin-video/viewer';
import { pluginDivider } from 'wix-rich-content-plugin-divider/viewer';
import { pluginImage } from 'wix-rich-content-plugin-image/viewer';
import { pluginGallery } from 'wix-rich-content-plugin-gallery/viewer';
import { pluginLink } from 'wix-rich-content-plugin-link/viewer';
import { pluginGiphy } from 'wix-rich-content-plugin-giphy/viewer';
import { pluginHtml } from 'wix-rich-content-plugin-html/viewer';
import { pluginFileUpload } from 'wix-rich-content-plugin-file-upload/viewer';
import { pluginLinkPreview } from 'wix-rich-content-plugin-link-preview/viewer';
import {
  pluginTextColor,
  pluginTextHighlight,
} from 'wix-rich-content-plugin-text-color/viewer';
import { pluginVerticalEmbed } from 'wix-rich-content-plugin-vertical-embed/viewer';
import { pluginTable } from 'wix-rich-content-plugin-table/viewer';

const PLUGINS = [
  pluginVideo({ disableDownload: true }),
  pluginDivider(),
  pluginImage(),
  pluginGallery(),
  pluginLink(),
  pluginGiphy(),
  pluginHtml(),
  pluginFileUpload(),
  pluginLinkPreview(),
  pluginTextColor(),
  pluginTextHighlight(),
  pluginVerticalEmbed(),
  pluginTable(),
];

export const rceUtils = {
  PLUGINS,
};
