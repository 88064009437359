import React from 'react';
import { ChallengeReward } from '@wix/ambassador-challenge-service-web/types';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { loadBadges } from '../../services/BadgesService';
import { LocationContext } from '../../contexts/Location/LocationContext';
import { getSiteOrigin } from '../../contexts/Location/urlHelpers';

import { st, classes } from './Badges.st.css';
import { Spinner } from '../Spinner';
import { useExperiments } from '@wix/wix-experiments-react';
import { Challenges } from '../../editor/types/Experiments';
import { V2Badge } from '@wix/ambassador-members-badges-server/types';

// TODO: shall be deprecated once experiment enableServerlessChallenge is finished
export interface IBadge {
  id: string;
  title: string;
  description: string;
  backgroundColor: string;
  textColor: string;
  icon: string;
  roleId: string;
  hasPermissions: boolean;
  slug: string;
  createDate: string;
  updateDate: string;
}

export enum ALIGNMENT {
  left = 'left',
  right = 'right',
  center = 'center',
}

export interface IBadgesProps {
  badges: V2Badge[];
  // TODO: 'rewards' and 'instance' shall be deprecated once experiment enableServerlessChallenge is finished
  rewards: ChallengeReward[];
  instance: string;
  title?: React.ReactNode;
  alignment?: ALIGNMENT;
}

export const AwardsBadges: React.FC<IBadgesProps> = (props) => {
  const [badges, setBadges] = React.useState<IBadge[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { isPreview, isEditor } = useEnvironment();
  const { location } = React.useContext(LocationContext);
  const { ready: isExperimentReady, experiments } = useExperiments();

  const origin = isEditor || isPreview ? '' : getSiteOrigin(location.baseUrl);

  const isServerlessChallengeExperimentEnabled = experiments.enabled(
    Challenges.enableServerlessChallenge,
  );

  React.useEffect(() => {
    if (isExperimentReady && isServerlessChallengeExperimentEnabled) {
      // early return to not fetch badges separately but use them from serverless challenges.api
      return;
    }

    const challengeRewardsIds = props.rewards[0]?.badgeIds || null;

    if (!challengeRewardsIds) {
      return;
    }

    setLoading(true);

    const fetchBadges = async () => {
      try {
        const badgesRes = await loadBadges(
          props.instance,
          origin,
          challengeRewardsIds,
        );
        const filteredBadges = badgesRes.badges.filter((badge) =>
          challengeRewardsIds.includes(badge.id),
        );

        setBadges(filteredBadges);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchBadges();
  }, []);

  const badgesArr: (V2Badge | IBadge)[] = isServerlessChallengeExperimentEnabled
    ? props.badges
    : badges;

  return (
    <div
      className={st(classes.root, { align: props.alignment || ALIGNMENT.left })}
    >
      {!!badgesArr.length && props.title}
      {loading || !isExperimentReady ? (
        <Spinner role="element" className={classes.spinner} />
      ) : (
        <div className={classes.badgeList}>
          {badgesArr.map((badge, idx) => (
            <span
              key={`${badge.title}-${idx}`}
              className={classes.badge}
              style={{
                color: badge.textColor,
                background: badge.backgroundColor,
              }}
            >
              {!!badge.icon && (
                <img
                  className={classes.image}
                  src={badge.icon}
                  alt={badge.slug}
                />
              )}
              <span className={classes.text}>{badge.title}</span>
            </span>
          ))}
        </div>
      )}
      {}
    </div>
  );
};
