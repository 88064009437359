import React from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  V1Challenge,
  V1DurationUnit,
} from '@wix/ambassador-challenge-service-web/types';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { getDurationForFlexyTimelines } from '../../../../../selectors/challenges';
import utils from '../../views/utils';

import { classes, st } from './ChallengeStats.st.css';

export interface IChallengeStatsProps {
  className?: string;
  type: 'Sidebar' | 'List';
  challenge: V1Challenge;
}

const renderDetailsItem = (description: string, dataHook: string) => {
  return (
    <li className={classes.detailsListItem} data-hook={dataHook}>
      <span className={classes.detailsListItemDescription}>{description}</span>
    </li>
  );
};

export const ChallengeStats: React.FunctionComponent<IChallengeStatsProps> = ({
  className,
  type,
  challenge,
}: IChallengeStatsProps) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  const {
    showDuration,
    showParticipants,
    showSteps,
    overviewItemsCount,
    stepsCount,
  } =
    type === 'List'
      ? utils.getBaseViewOptionsForList(challenge, settings)
      : utils.getBaseViewOptionsForSidebar(challenge, settings);

  const duration = getDurationForFlexyTimelines(challenge);

  return (
    <ul
      style={{
        gridTemplateColumns: `repeat(${overviewItemsCount}, 135px)`,
      }}
      className={`${st(classes.root, {
        mobile: isMobile,
        type: type.toLowerCase(),
        align: utils.getHeaderAlignByType(type, settings),
      })} ${className}`}
      data-hook="challenge-page-details-list"
    >
      {showDuration
        ? renderDetailsItem(
            duration?.unit === V1DurationUnit.DAYS
              ? t(`challenge.page.details.days_icu`, { count: duration?.value })
              : t(`challenge.page.details.weeks_icu`, {
                  count: duration?.value,
                }),
            'challenge-page-details-item-duration',
          )
        : null}

      {showSteps
        ? renderDetailsItem(
            t(`challenge.page.details.steps_icu`, { count: stepsCount }),
            'challenge-page-details-item-steps',
          )
        : null}

      {showParticipants
        ? renderDetailsItem(
            t(`challenge.page.details.participants_icu`, {
              count: challenge.participantsSummary.participantsNumber,
            }),
            'challenge-page-details-item-participants',
          )
        : null}
    </ul>
  );
};
ChallengeStats.displayName = 'ChallengeStats';
ChallengeStats.defaultProps = {
  className: '',
};
