import React from 'react';
import { classes } from './singlePricingOption.st.css';

export interface ISinglePricingOptionProps {}

export const SinglePricingOption: React.FC<ISinglePricingOptionProps> = (
  props,
) => {
  return <div className={classes.root}>{props.children}</div>;
};
