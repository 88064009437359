import {
  IUserType,
  UserState,
} from '../../../../../../contexts/User/UserContext';
import { ButtonNames } from '../../../../../../contexts/BI/interfaces';
import { ParticipantState } from '@wix/ambassador-challenge-service-web/types';

interface GetButtonBIName {
  userType: IUserType;
}

export function getJoinBIButtonName({
  userType,
}: GetButtonBIName): ButtonNames {
  switch (userType) {
    case ParticipantState.JOIN_REQUESTED:
    case ParticipantState.JOINED:
    case ParticipantState.RUNNING:
      return ButtonNames.GoToOneApp;
    case UserState.VISITOR:
      return ButtonNames.LoginAndJoinToTheChallenge;
    default:
    case ParticipantState.COMPLETED: // todo clarify if user can rejoin
    case ParticipantState.JOIN_REJECTED: // todo clarify if user send second join request
    case ParticipantState.LEFT: // todo clarify if user can rejoin
    case ParticipantState.REMOVED: // todo clarify if user can rejoin
    case ParticipantState.FAILED: // todo clarify if user can rejoin
    case UserState.MEMBER:
    case ParticipantState.INVITED:
    case ParticipantState.PAYMENT_REQUESTED:
    case ParticipantState.PAYMENT_STARTED:
      return ButtonNames.JoinToTheChallenge;
  }
}
