import React from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { RadioButton } from '../../../../../components-shared/RadioButton';

import { st, classes } from './radioGroup.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { IChoiceUIRightness } from '../Questionnaire/fields/fields';

export interface RadioOption {
  dataHook?: string;
  title: string;
  valueTitle?: string;
  value?: any;
  hideControl?: boolean;
  iconURL?: string;
  ariaLabel?: string;
  rightness?: string; // UI states for quiz statuses
}

export interface RadioGroupProps {
  options: RadioOption[];
  selectedValue: any;
  isDisabled?: boolean;
  isMobile?: boolean;
  onSelect(selected: RadioOption): void;
  name?: string;
  withFocusBackground?: boolean;
  id: string;
  isMultiple?: boolean;
}

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const {
    isDisabled = false,
    withFocusBackground = true,
    id,
    isMultiple,
  } = props;

  const { isMobile } = useEnvironment();

  return (
    <div
      role="radiogroup"
      aria-labelledby={`field-${id}`}
      className={st(classes.root, {
        mobile: isMobile,
        disabled: isDisabled,
        focusBackground: withFocusBackground,
      })}
    >
      {props.options.map((option, index) => {
        const {
          dataHook = null,
          title,
          valueTitle = '',
          hideControl,
          iconURL,
          rightness,
        } = option;
        const checked = isMultiple
          ? props.selectedValue?.includes?.(option.value)
          : props.selectedValue === option.value;

        return (
          <label
            key={index}
            className={classNames(classes.radioButtonGroupItem, {
              [classes.radioButtonGroupItemChecked]: checked,
              [classes.radioButtonGroupItemThemeRightChecked]:
                rightness === IChoiceUIRightness.RIGHT_CHECKED,
              [classes.radioButtonGroupItemThemeRightMissed]:
                rightness === IChoiceUIRightness.RIGHT_MISSED,
              [classes.radioButtonGroupItemThemeWrong]:
                rightness === IChoiceUIRightness.WRONG,
            })}
            data-hook={dataHook}
          >
            {!isDisabled && (
              <RadioButton
                // aria-label={ariaLabel || ''}
                name={props.name || 'radio-button-group'}
                onChange={debounce(() => {
                  props.onSelect(option);
                }, 200)}
                checked={checked && !isDisabled}
                hideControl={hideControl}
                iconURL={iconURL}
              />
            )}
            <div className={classes.radioButtonGroupInfoContainer}>
              <span className={classes.radioButtonGroupItemTitle}>{title}</span>
              <span className={classes.radioButtonGroupItemValue}>
                {valueTitle}
              </span>
            </div>
          </label>
        );
      })}
    </div>
  );
};

RadioGroup.defaultProps = {
  isDisabled: false,
  withFocusBackground: true,
};
