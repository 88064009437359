const BADGES_URL = '/_api/members-badges-server/v1/badges/query';

let badges;

export const loadBadges = async (
  instance: string,
  origin: string,
  ids: string[],
) => {
  if (badges) {
    return badges;
  }

  if (!instance || !ids) {
    return;
  }

  try {
    // todo do it in the controller
    badges = await (
      await fetch(`${origin}${BADGES_URL}`, {
        method: 'POST',
        headers: {
          Authorization: instance,
        },
        body: JSON.stringify({
          query: {
            ids: {
              values: ids,
            },
          },
        }),
      })
    ).json();

    return badges;
  } catch (err) {
    console.error('Failed fetching Badges:', err);
  }
};
