import React from 'react';
import { MediaItem } from '@wix/ambassador-challenge-service-web/types';
import { IImageData, imageDataToMediaUrl } from '@wix/challenges-web-library';
import { getVideoUrl } from '@wix/challenges-web-library/dist/src/helpers/video';

import { st, classes } from './MediaCover.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { GeneralDataContext } from '../../contexts/GeneralDataProvider/GeneralDataContext';

export interface IMediaCover {
  media: MediaItem;
  className?: string;
  maxHeight?: number;
  fit?: 'contain' | 'cover'; // by default cover
}

export const MediaCover: React.FC<IMediaCover> = (props) => {
  const { isMobile } = useEnvironment();
  const { fit = 'cover' } = props;
  const { isFullWidthLayout } = React.useContext(GeneralDataContext);
  if (!props.media) {
    return null;
  }

  return (
    <figure
      className={st(
        classes.root,
        {
          mobile: isMobile,
          fit,
          fullWidth: isFullWidthLayout,
        },
        props.className,
      )}
    >
      {props.media?.image ? (
        <img
          style={{
            maxHeight: props.maxHeight,
          }}
          className={classes.mediaImage}
          src={`${imageDataToMediaUrl({
            ...(props?.media.image as IImageData),
            width: 1000,
            height: 800,
          })}`}
        />
      ) : (
        <video
          controls={true}
          src={`${getVideoUrl(props.media?.video?.url)}`}
          className={classes.mediaImage}
        ></video>
      )}
    </figure>
  );
};
