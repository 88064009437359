import {
  StyleParamType,
  createStylesParams,
} from '@wix/yoshi-flow-editor/tpa-settings';

import { IChallengeStyles } from './challengeStyles.types';

export const challengeStyles = createStylesParams<IChallengeStyles>({
  mobChallengeNameSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 28,
  },
  mobDateSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobPriceSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobSectionTitleSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 28,
  },
  mobCounterNumberSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobCounterDescriptionSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobDescriptionSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobButtonSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
});
