import React from 'react';
import {
  addDays,
  addWeeks,
  compareAsc,
  differenceInDays,
  format,
  isEqual,
  subWeeks,
} from 'date-fns';
import {
  getChallengeValidDate,
  getRightDateFromBackend,
  getWeekRange,
} from '../../selectors/dates';

import { RangeSelector } from '../RangeSelector';
import { ALIGNMENT } from '../RangeSelector/contants';

export interface IWeeksSelectorProps {
  locale: string;
  min: string;
  max: string;
  sharedCurrentDate: Date;
  align?: ALIGNMENT;
  onChange(e): void;
  onCurrentDateChange?(currentDate: Date): void;
  t(key: string, opts?: any): string;
  className?: string;
}

export interface IWeeksSelectorState {
  currentDate: Date;
  locales: any;
}

export interface IGetWeekRange {
  from: Date;
  to: Date;
}

export class WeeksSelector extends React.PureComponent<
  IWeeksSelectorProps,
  IWeeksSelectorState
> {
  static displayName = 'WeeksSelector';

  getDateFrame = () => {
    return {
      finish: this.props.max,
      start: this.props.min,
    };
  };

  state = {
    currentDate:
      this.props.sharedCurrentDate ||
      getChallengeValidDate(this.getDateFrame(), new Date()),
    locales: null,
  };

  async componentDidMount() {
    const fetchedLocales = await import('date-fns/locale');
    this.setState({ locales: fetchedLocales });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.sharedCurrentDate &&
      !isEqual(this.props.sharedCurrentDate, prevProps.currentDate) &&
      !isEqual(this.props.sharedCurrentDate, prevState.currentDate)
    ) {
      this.setState({
        currentDate: this.props.sharedCurrentDate,
      });
    }
  }

  getMinDate() {
    return getRightDateFromBackend(this.props.min);
  }

  getMaxDate() {
    return getRightDateFromBackend(this.props.max);
  }

  handleChange = (direction: 1 | -1) => {
    this.setState(
      direction > 0
        ? { currentDate: addWeeks(this.state.currentDate, 1) }
        : { currentDate: subWeeks(this.state.currentDate, 1) },
      () => {
        const range = getWeekRange(this.getMinDate(), this.state.currentDate);

        this.props.onChange(range);
        if (this.props.onCurrentDateChange) {
          this.props.onCurrentDateChange(this.state.currentDate);
        }
      },
    );
  };

  render() {
    const { currentDate, locales } = this.state;
    if (!locales) {
      return null;
    }
    const { from, to } = getWeekRange(this.getMinDate(), currentDate);

    const isMinDisabled = compareAsc(from, this.getMinDate()) <= 0;
    const isMaxDisabled =
      this.props.max && compareAsc(addDays(to, 1), this.getMaxDate()) > 0;

    const start = format(from, 'dd');
    const end = format(to, 'dd');

    const monthFromString = format(from, 'MMM', {
      locale: locales[this.props.locale],
    });
    const monthToString = format(to, 'MMM', {
      locale: locales[this.props.locale],
    });

    const rangeString =
      monthFromString === monthToString
        ? `${monthFromString} ${start} - ${end}`
        : `${monthFromString} ${start} - ${monthToString} ${end}`;

    const isWeekOrLess =
      this.props.max &&
      differenceInDays(this.getMaxDate(), this.getMinDate()) <= 7;

    return (
      <RangeSelector
        value={`${this.props.t('weeks.prefix', { dateRange: rangeString })}`}
        nextText={isWeekOrLess ? null : this.props.t('weeks.button.next')}
        prevText={isWeekOrLess ? null : this.props.t('weeks.button.previous')}
        isPrevButtonDisabled={isMinDisabled || isWeekOrLess}
        isNextButtonDisabled={isMaxDisabled || isWeekOrLess}
        onNextClick={() => {
          !isMaxDisabled && this.handleChange(1);
        }}
        onPrevClick={() => {
          !isMinDisabled && this.handleChange(-1);
        }}
        align={this.props.align}
        className={this.props.className}
      />
    );
  }
}
