import * as React from 'react';
import { classes, st } from './SidebarLayoutButton.st.css';
import {
  Button,
  PRIORITY as ButtonPRIORITY,
  SIZE as ButtonSIZE,
} from 'wix-ui-tpa/Button';
import { useSettingsEvent } from '../../../../../../contexts/SettingsEvents/SettingsEvents';
import { IStyledButtonProps } from '../interfaces';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const SidebarLayoutButton: React.FC<IStyledButtonProps> = (props) => {
  const { buttonState: SettingsEventsButtonState } = useSettingsEvent();
  const { isMobile } = useEnvironment();
  const {
    buttonState = SettingsEventsButtonState,
    className,
    size = ButtonSIZE.small,
    fullWidth = isMobile,
    priority = ButtonPRIORITY.primary,
  } = props;
  return (
    <Button
      fullWidth={fullWidth}
      data-hook={props['data-hook']}
      className={st(classes.root, { buttonState }, className)}
      disabled={props.disabled}
      priority={priority}
      size={size}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};
