import { TextAlignment } from '../components/ChallengesPage/Settings/challengeSettings/challengeSettings.types';
import { ALIGNMENT as TabsALIGNMENT } from 'wix-ui-tpa/Tabs';
import { ALIGNMENT as WeekSelectorAlignment } from '../components-shared/RangeSelector/contants';
import { ALIGNMENT as AccordionAlignment } from '../components-shared/Accordion/constants';
import { ALIGNMENT as AwardsBadgesAlignment } from '../components-shared/Badges';

export const convertToTabsAlignment = (
  alignment: TextAlignment,
): TabsALIGNMENT => {
  switch (alignment) {
    default:
    case TextAlignment.Left:
      return TabsALIGNMENT.left;
    case TextAlignment.Center:
      return TabsALIGNMENT.center;
    case TextAlignment.Right:
      return TabsALIGNMENT.right;
  }
};

export function covertTextToWeekSelectorAlignment(
  alignment: TextAlignment,
): WeekSelectorAlignment {
  switch (alignment) {
    default:
    case TextAlignment.Left:
    case TextAlignment.Center:
      return WeekSelectorAlignment.left;
    case TextAlignment.Right:
      return WeekSelectorAlignment.right;
  }
}

export function convertTextToAccordionAlignment(
  textAlignment: TextAlignment,
): AccordionAlignment {
  switch (textAlignment) {
    default:
    case TextAlignment.Center:
    case TextAlignment.Left:
      return AccordionAlignment.left;
    case TextAlignment.Right:
      return AccordionAlignment.right;
  }
}

export function covertTextToBadgesAlignment(
  alignment: TextAlignment,
): AwardsBadgesAlignment {
  switch (alignment) {
    default:
    case TextAlignment.Center:
    case TextAlignment.Left:
      return AwardsBadgesAlignment.left;
    case TextAlignment.Right:
      return AwardsBadgesAlignment.right;
  }
}

export function convertTextAlignmentToPopoverPlacement(
  alignment: TextAlignment,
) {
  switch (alignment) {
    default:
    case TextAlignment.Left:
    case TextAlignment.Center:
      return 'bottom-end';
    case TextAlignment.Right:
      return 'bottom-start';
  }
}

export function setHelpArticleId(id: string): void {
  window?.Wix?.Settings?.setHelpArticle(id);
}
