import {
  FeedbackItem,
  FeedbackItemSettings,
} from '@wix/ambassador-challenge-service-web/types';

const fixNumberPattern = /^\d{1,8}(\.\d{0,2})?$/;

const utils = {
  fieldTypeToFieldName: {
    text: 'text',
    quantity: 'quantity',
    numeric: 'number',
    media: 'media',
    multipleChoice: 'choice',
    multiSelect: 'multiSelect',
  },
  getFieldType(field: FeedbackItemSettings): string {
    return Object.keys(field.type)[0];
  },

  getFieldLabel(t: Function, field: FeedbackItemSettings) {
    return `${field.question} (${
      field.isRequired
        ? t('questionnaire.required')
        : t('questionnaire.optional')
    })`;
  },

  getFieldValueFromBackend(
    feedbackItems: FeedbackItem[] = [],
    field: FeedbackItemSettings,
  ): any {
    const feedbackItem = feedbackItems.find(
      (item) => item.feedbackItemSettingsId === field.id,
    );
    const fieldName = utils.fieldTypeToFieldName[utils.getFieldType(field)];
    let value = feedbackItem ? feedbackItem[fieldName] : null;

    if (value) {
      switch (fieldName) {
        case 'quantity':
          value = value.amount;

          break;
        case 'media':
          value = utils.getMediaFromBackend(value);

          break;
        case 'choice':
          value = value.choiceId;

          break;
        case 'multiSelect':
          value = value.choiceIds;

          break;
        default:
      }
    }

    if (value === null) {
      value = utils.getEmptyValue(field);
    }

    return value;
  },

  getFieldValueForBackend(
    field: FeedbackItemSettings,
    fieldsValues: any = {},
  ): any {
    const fieldType = utils.getFieldType(field);
    let value = fieldsValues[field.id];

    switch (fieldType) {
      case 'text':
        value = value || null;

        break;
      case 'media':
        value = utils.getMediaForBackend(value);

        break;
      case 'quantity':
        value = value
          ? {
              amount: parseFloat(value),
              unitName: field?.type?.quantity?.unitName,
            }
          : null;

        break;
      case 'multipleChoice':
        value = value
          ? {
              choiceId: value,
            }
          : null;

        break;
      case 'multiSelect':
        value =
          value && value.length
            ? {
                choiceIds: value,
              }
            : null;

        break;

      case 'numeric':
      default:
    }

    if (
      ![
        'text',
        'quantity',
        'numeric',
        'media',
        'multipleChoice',
        'multiSelect',
      ].includes(fieldType)
    ) {
      return null;
    }

    return value;
  },

  getMediaFromBackend(value: any): any {
    return value && value.mediaItems?.length
      ? value.mediaItems.map((file) => {
          let result = null;

          if (file.image) {
            result = {
              ...file.image,
              mediaType: 'image',
            };
          } else if (file.video) {
            result = {
              ...file.video,
              mediaType: 'video',
            };
          }

          return result;
        })
      : null;
  },

  getMediaForBackend(value) {
    return value?.length
      ? {
          mediaItems: value.map((file) => {
            return {
              [file.mediaType]: { ...file, mediaType: undefined },
            };
          }),
        }
      : null;
  },

  getEmptyValue(field: FeedbackItemSettings): any {
    const fieldType = utils.getFieldType(field);

    switch (fieldType) {
      case 'media':
      case 'multiSelect':
        return [];
      default:
        return null;
    }
  },

  fixNumber(value: string): string {
    if (value && !fixNumberPattern.test(value)) {
      return null;
    }

    return value;
  },
};

export default utils;
