import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { ThankYouPageWithProviders } from '../../components/ThankYouPage/Widget/Widget';
import { PaymentPageWithProviders } from '../../components/PaymentPage/Widget/Widget';
import { ChallengePageWithProviders } from '../../components/ChallengesPage/Widget/Widget';
import { applyProviders } from '../../services/applyProviders';
import { withProviders } from '../../contexts/main/withProviders';
import {
  ILocationContext,
  LocalRouting,
  Pages,
} from '../../contexts/Location/LocationContext';
import { withLocation } from '../../contexts/Location/withLocation';
import { RouteComponentProps, StaticRouter } from 'react-router';
import { withGeneralData } from '../../contexts/GeneralDataProvider/withGeneralData';
import { IGeneralDataContext } from '../../contexts/GeneralDataProvider/GeneralDataContext';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export type RoutingProps = ILocationContext;

const ChallengeRoutes: React.FC<any> = applyProviders(
  React.memo((props: RouteComponentProps & RoutingProps) => {
    return (
      <div>
        <Redirect push to={props.currentLocation} />
        <Switch>
          <Route path={LocalRouting[Pages.Details]} exact>
            <ChallengePageWithProviders {...props} />
          </Route>
          <Route path={LocalRouting[Pages.ThankYou]}>
            <ThankYouPageWithProviders />
          </Route>
          <Route path={LocalRouting[Pages.Payment]}>
            <PaymentPageWithProviders />
          </Route>
        </Switch>
      </div>
    );
  }),
  [withLocation, withRouter],
);

export const Routing: React.FC<any> = applyProviders(
  (props: RoutingProps & IGeneralDataContext) => {
    const { isSSR } = useEnvironment();
    if (process.env.NODE_ENV === 'test' || isSSR) {
      return (
        <StaticRouter location={props.currentLocation}>
          <ChallengeRoutes {...props} />
        </StaticRouter>
      );
    }

    return (
      <Router basename={props.basePath}>
        <ChallengeRoutes {...props} />
      </Router>
    );
  },
  [withProviders, withGeneralData],
);
