import React from 'react';

import { Chevron } from './Chevron';

import { st, classes } from './RangeSelector.st.css';
import { ALIGNMENT } from './contants';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export interface IRangeSelectorProps {
  value: string;
  nextText?: string;
  prevText?: string;
  isNextButtonDisabled: boolean;
  isPrevButtonDisabled: boolean;
  onNextClick(): void;
  onPrevClick(): void;
  className?: string;
  align?: ALIGNMENT;
}

export const RangeSelector: React.FC<IRangeSelectorProps> = (props) => {
  const {
    value,
    isNextButtonDisabled,
    isPrevButtonDisabled,
    onNextClick,
    onPrevClick,
    className,
    align = ALIGNMENT.left,
  } = props;
  const { isMobile } = useEnvironment();

  return (
    <section
      className={st(classes.root, { mobile: isMobile, align }, className)}
    >
      <span className={classes.text}>{value}</span>
      <div className={classes.buttonsContainer}>
        {props.prevText ? (
          <button
            onClick={onPrevClick}
            disabled={isPrevButtonDisabled}
            className={`${classes.button} ${classes.buttonPrev} ${
              isPrevButtonDisabled && classes.buttonDisabled
            }`}
          >
            <Chevron />
            {!!props.prevText && (
              <span className={classes.label}>{props.prevText}</span>
            )}
          </button>
        ) : null}

        {props.nextText ? (
          <button
            onClick={onNextClick}
            disabled={isNextButtonDisabled}
            className={`${classes.button} ${classes.buttonNext} ${
              isNextButtonDisabled && classes.buttonDisabled
            }`}
          >
            {!!props.nextText && (
              <span className={classes.label}>{props.nextText}</span>
            )}
            <Chevron />
          </button>
        ) : null}
      </div>
    </section>
  );
};
