import React from 'react';
import { st, classes } from './SocialShare.st.css';
import { SocialBar } from 'wix-ui-tpa/SocialBar';
import { Icons } from 'wix-ui-tpa/dist/src';
import { CopyUrlButton } from 'wix-ui-tpa/CopyUrlButton';
import { ButtonNames } from '../../../../../contexts/BI/interfaces';
import { useEnvironment, useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';

export interface ISocialShareProps {
  shareTitle: string;
  shareUrl: string;
  originalUrl: string;
  className?: string;
}

export interface ISocialShareState {}

export const SocialShare: React.FC<ISocialShareProps> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const bi = useBi();

  const { shareTitle, shareUrl, originalUrl, className } = props;

  return (
    <div
      className={st(
        classes.root,
        {
          mobile: isMobile,
        },
        className,
      )}
    >
      <h2 className={classes.socialShareTitle}>
        {t('live.challenges-page.social-share.title')}
      </h2>

      <SocialBar className={classes.socialShareBar}>
        <SocialBar.Icon
          tooltip={t('live.challenges-page.social-share.facebook')}
          aria-label={t('live.challenges-page.social-share.facebook')}
          icon={<Icons.SocialIcons.Facebook />}
          href={`//facebook.com/sharer/sharer.php?u=${shareUrl}`}
          // @ts-expect-error
          target="_blank"
          onClick={async () => {
            await bi.report(
              memberWebAppButtonClickV2({
                buttonName: ButtonNames.ShareFacebook,
              }),
            );
          }}
        />
        <SocialBar.Icon
          tooltip={t('live.challenges-page.social-share.twitter')}
          aria-label={t('live.challenges-page.social-share.twitter')}
          icon={<Icons.SocialIcons.Twitter />}
          href={`//twitter.com/intent/tweet?text=${shareTitle}&url=${originalUrl}`}
          // @ts-expect-error
          target="_blank"
          onClick={async () => {
            await bi.report(
              memberWebAppButtonClickV2({
                buttonName: ButtonNames.ShareTwitter,
              }),
            );
          }}
        />
        <SocialBar.Icon
          tooltip={t('live.challenges-page.social-share.linkedin')}
          aria-label={t('live.challenges-page.social-share.linkedin')}
          icon={<Icons.SocialIcons.Linkedin />}
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`}
          // @ts-expect-error
          target="_blank"
          onClick={async () => {
            await bi.report(
              memberWebAppButtonClickV2({
                buttonName: ButtonNames.ShareLinkedin,
              }),
            );
          }}
        />

        {originalUrl ? (
          <CopyUrlButton
            aria-label={t('live.challenges-page.social-share.copy-link')}
            tooltipText={t('live.challenges-page.social-share.copy-link')}
            successText={t(
              'live.challenges-page.social-share.copy-link-reaction',
            )}
            url={originalUrl}
            onClick={async () => {
              await bi.report(
                memberWebAppButtonClickV2({
                  buttonName: ButtonNames.CopyLink,
                }),
              );
            }}
          />
        ) : null}
      </SocialBar>
    </div>
  );
};

SocialShare.displayName = 'SocialShare';
