import React from 'react';

import { PaidPlansContext } from '../../../../../contexts/PaidPlans/paidPlansContext';
import userTypeHandlers from '../../../../../contexts/User/helpers/userTypeHandlers';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ChallengeDataContext } from '../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { UserContext } from '../../../../../contexts/User/UserContext';

import { getPricingOptions } from './helpers/getPricingOptions';
import { getChallengeEligiblePaidPlans } from './helpers/getChallengeEligiblePaidPlans';
import { getPaidPlanStartPrice } from './helpers/getPaidPlanPrice';

import {
  getPaidPlansJoinedName,
  serverPricingToClientPriceAndCurrencyString,
  isHiddenPlansOnly,
} from '@wix/challenges-web-library/dist/src';

import { RadioGroup } from '../RadioGroup/RadioGroup';
import { PageSection } from '../../../../../components-shared/PageSection';
import { SinglePricingOption } from '../SinglePricingOption/SinglePricingOption';

import { IPricingProps, SelectedPaymentOption } from './interfaces';
import {
  FREE_CHALLENGE_PRICING,
  ONLY_ONE_TIME_PAYMENT_PRICING,
  ONLY_PAID_PLANS_DATA_HOOK,
  ONLY_HIDDEN_PAID_PLANS_DATA_HOOK,
  INCLUDED_IN_YOUR_PLAN_DATA_HOOK,
} from './constants';
import { getPricingViewOptions } from '../../../../../services/Pricing/getPricingViewOptions';

export const SinglePricingSection: React.FC<{
  title?: string;
  className: string;
  dataHook?: string;
  content: string;
}> = ({ title, className, dataHook, content }) => {
  const { t } = useTranslation();

  return (
    <PageSection
      className={className}
      title={title || t('challenge.page.pricing-options.title-for-free')}
      dataHook={dataHook || ''}
    >
      <SinglePricingOption>{content}</SinglePricingOption>
    </PageSection>
  );
};

export const ChoicePricingSection: React.FC<{
  title?: string;
  className: string;
  dataHook?: string;
  isDisabled: boolean;
  selectedValue: any;
  onSelect(value: any): void;
  options: any[];
}> = ({
  title,
  className,
  dataHook,
  isDisabled,
  selectedValue,
  onSelect,
  options,
}) => {
  const { t } = useTranslation();

  return (
    <PageSection
      className={className}
      title={title || t('challenge.page.pricing-options.title-for-free')}
      dataHook={dataHook || ''}
    >
      <RadioGroup
        id="price-selection"
        selectedValue={selectedValue}
        onSelect={onSelect}
        options={options}
        isDisabled={isDisabled}
      />
    </PageSection>
  );
};

export const Pricing: React.FC<IPricingProps> = ({
  className,
  disabled,
  disabledEligible,
  selectedPaymentOption,
  onPaymentSelected,
}: IPricingProps) => {
  const { t } = useTranslation();
  const {
    challengeData: { challenge },
  } = React.useContext(ChallengeDataContext);
  const { userPaidPlans, eligiblePlans } = React.useContext(PaidPlansContext);
  const { userType } = React.useContext(UserContext);

  const { isHasSinglePayment, challengePaidPlans } = getPricingViewOptions(
    challenge,
    userPaidPlans,
    eligiblePlans,
    false,
    false,
  );

  const { challengePaidPlans: challengeVisiblePaidPlans } =
    getPricingViewOptions(challenge, userPaidPlans, eligiblePlans, true, false);

  if (userTypeHandlers.isJoinedAlready(userType)) {
    return null;
  }

  /*
    The order below is important.
    Check the already purchased pps first.
    Then, do a check for hidden only pps and process it in the correct way.
    Then we can remove hidden / archived pps from the list and work only with active ones.
   */

  /* User already purchased one of pricing plans (eligible means paid) */

  const challengeEligiblePaidPlans = getChallengeEligiblePaidPlans(
    eligiblePlans,
    userPaidPlans,
    challenge.id,
  );

  if (challengeEligiblePaidPlans.length && !disabledEligible) {
    if (selectedPaymentOption !== SelectedPaymentOption.PaidPlans) {
      onPaymentSelected(SelectedPaymentOption.PaidPlans);
    }

    return (
      <SinglePricingSection
        className={className}
        dataHook={INCLUDED_IN_YOUR_PLAN_DATA_HOOK}
        content={t('pricing.payment-option.subscription.already-included')}
      />
    );
  }

  /* There are only hidden pricing plans */

  const isHiddenOnly = isHiddenPlansOnly(
    isHasSinglePayment,
    challengePaidPlans,
  );

  if (isHiddenOnly) {
    if (selectedPaymentOption !== SelectedPaymentOption.PaidPlans) {
      onPaymentSelected(SelectedPaymentOption.PaidPlans);
    }

    return (
      <SinglePricingSection
        className={className}
        dataHook={ONLY_HIDDEN_PAID_PLANS_DATA_HOOK}
        content={t('challenge.page.pricing-options.paid-general')}
      />
    );
  }

  /* Here we can get the pricing options for visible plans only */

  const pricingOptions = getPricingOptions(
    t,
    challenge,
    userPaidPlans,
    eligiblePlans,
    true,
    false,
  );

  if (!selectedPaymentOption && pricingOptions?.[0]) {
    onPaymentSelected(pricingOptions[0].value as any);
  }

  /* Challenge is free */

  if (!pricingOptions.length) {
    return (
      <SinglePricingSection
        className={className}
        dataHook={FREE_CHALLENGE_PRICING}
        content={t('challenge.page.pricing-options.free')}
      />
    );
  }

  /* Challenges has different pricing options (one time payment + active paid plans)  */

  if (pricingOptions.length > 1) {
    return (
      <ChoicePricingSection
        className={className}
        isDisabled={disabled || pricingOptions.length === 1}
        selectedValue={selectedPaymentOption}
        onSelect={(option) => {
          onPaymentSelected(option.value);
        }}
        options={pricingOptions}
      />
    );
  }

  /* Pricing plans only */

  if (pricingOptions[0].value === SelectedPaymentOption.PaidPlans) {
    const paidPlanName = getPaidPlansJoinedName(
      t,
      challengeVisiblePaidPlans,
      'pricing.payment-option.subscription_icu',
      true,
    );
    const paidPlanStartPrice = getPaidPlanStartPrice(
      challengeVisiblePaidPlans,
      t,
    );

    return (
      <SinglePricingSection
        className={className}
        dataHook={ONLY_PAID_PLANS_DATA_HOOK}
        content={`${paidPlanName}, ${paidPlanStartPrice.toLocaleLowerCase()}`}
      />
    );
  }

  /* One time payment only */

  if (pricingOptions[0].value === SelectedPaymentOption.SinglePayment) {
    if (selectedPaymentOption !== SelectedPaymentOption.SinglePayment) {
      onPaymentSelected(SelectedPaymentOption.SinglePayment);
    }

    return (
      <SinglePricingSection
        className={className}
        dataHook={ONLY_ONE_TIME_PAYMENT_PRICING}
        content={serverPricingToClientPriceAndCurrencyString(
          challenge.settings.pricing as any,
        )}
      />
    );
  }
};
