import React from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { classes, st } from './ChallengeSummary.st.css';
import { useChallengeData } from '../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { isSelfPaced } from '../../../../../selectors/challenges';
import {
  isStepResolved,
  getFlatStepsList,
  isParticipantCompletedChallenge,
} from '../../views/utils';
import { useSections } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { useParticipantSteps } from '../../../../../contexts/ParticipantStepsDataProvider/ParticipantStepsContext';
import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';
import { Challenges } from '../../../../../editor/types/Experiments';
import { ChallengeDots } from '../ChallengeDots';
import { SocialGroup } from '../../../../../components-shared/SocialGroup';
import { ProgressBar } from '../../../../../components-shared/ProgressBar';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import { useGeneralData } from '../../../../../contexts/GeneralDataProvider/GeneralDataContext';
import { useLeaveChallengeModal } from '../../../../../contexts/LeaveChallengeModal/LeaveChallengeModalContext';
import { useUser } from '../../../../../contexts/User/UserContext';

export interface ISummary {}

export const ChallengeSummary: React.FC<ISummary> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const {
    challengeData: { challenge },
    isGroupsInstalled,
  } = useChallengeData();
  const { listParticipantSections } = useSections();
  const { participantSteps } = useParticipantSteps();
  const settings = useSettings();
  const { instance } = useGeneralData();
  const { showModal } = useLeaveChallengeModal();
  const { participant } = useUser();

  const isSPC = isSelfPaced(challenge);
  const flatSteps = getFlatStepsList({
    listParticipantSections,
    participantSteps,
  });
  const resolvedValue = isSPC
    ? Math.floor(
        (flatSteps.reduce(
          ((resolved: number, step: V1ParticipantStep) => {
            return isStepResolved(step) ? ++resolved : resolved;
          }) as any,
          0,
        ) /
          (flatSteps.length || 1)) *
          100,
      )
    : null;

  const isFinished =
    isParticipantCompletedChallenge(participant) &&
    experiments.enabled(Challenges.enableHappyScreens);
  return (
    <div
      className={st(classes.root, {
        align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
        mobile: isMobile,
      })}
    >
      {isFinished ? (
        <p className={classes.completed}>{t('challenge.page.completed')}</p>
      ) : null}
      <h1 className={classes.title}>
        {(challenge as any)?.shouldTranslateTitle
          ? t(challenge.settings.description.title)
          : challenge.settings.description.title}
      </h1>
      <div className={classes.dotsMenu}>
        <ChallengeDots
          type="Sidebar"
          onLeave={() => {
            showModal({
              participantId: participant.id,
              challengeId: challenge.id,
            });
          }}
        />
      </div>

      <SocialGroup
        isGroupInstalled={isGroupsInstalled}
        className={classes.groupTitle}
        instance={instance}
        groupId={challenge?.settings?.socialGroupId}
        mode="compact"
      />

      {isSPC ? (
        <ProgressBar
          align={settings.get(challengeSettings.sidebarLayoutTextAlignment)}
          className={classes.progress}
          min={0}
          max={100}
          label={`${resolvedValue}%`}
          value={resolvedValue}
          aria-describedby={`${resolvedValue}%`}
        />
      ) : null}
    </div>
  );
};

ChallengeSummary.displayName = 'ChallengeSummaryControls';
