import React from 'react';
import { IChallengePageProps } from './interfaces';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { withGeneralData } from '../../../contexts/GeneralDataProvider/withGeneralData';
import { withResolveStepData } from '../../../contexts/ResolveStep/withResolveStepData';
import { withParticipantStepsData } from '../../../contexts/ParticipantStepsDataProvider/withParticipantStepsData';
import { withChallengeData } from '../../../contexts/ChallengeDataProvider/withChallengeData';
import { withParticipantSections } from '../../../contexts/ParticipantSections/withParticipantSections';
import { withToast } from '../../../contexts/ToastContext/withToast';
import { withUser } from '../../../contexts/User/withUser';
import { withLocation } from '../../../contexts/Location/withLocation';
import { withSettingsEvents } from '../../../contexts/SettingsEvents/SettingsEvents';
import { withInviteLink } from '../../../contexts/InviteLink/InviteLinkContext';
import { withBI } from '../../../contexts/BI/withBI';
import {
  IWixSDKContext,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { VisitorPage } from './views/VisitorPage/VisitorPage';
import ListLayoutForParticipant from './views/ListLayout/ListLayoutForParticipant';
import SidebarLayoutForParticipant from './views/SidebarLayout/SidebarLayoutForParticipant';
import { withPaidPlans } from '../../../contexts/PaidPlans/paidPlansContext';
import { applyProviders } from '../../../services/applyProviders';
import { isParticipantPage } from '../../../selectors/participants';
import { Routing } from '../../../components-shared/Routing/Routing';
import { withLoadingAPI } from '../../../contexts/Loading/LoadingContext';

import { classes } from './Widget.st.css';
import { UserState } from '../../../contexts/User/UserContext';
import settingsParams from '../settingsParams';
import { RolesState } from './settingsEvents';
import {
  IChallengeSettings,
  ParticipantLayout,
} from '../Settings/challengeSettings/challengeSettings.types';
import {
  isForcedPreviewParticipant,
  isForcedPreviewVisitor,
} from '../../../selectors/isForcedPreview';
import { ChallengeCompletedModal } from '../../../components-shared/ChallengeCompletedModal/ChallengeCompletedModal';
import { useFullscreen } from '../../../contexts/Fullscreen/FullscreenContext';
import { LeaveChallengeModalProvider } from '../../../contexts/LeaveChallengeModal/LeaveChallengeModalProvider';
// import { withProviders } from '../../../contexts/main/withProviders';

export const ChallengePage: React.FC<IChallengePageProps & IWixSDKContext> = (
  props,
) => {
  const { t } = useTranslation();
  const { isEditor, isPreview, isViewer, isMobile, language } =
    useEnvironment();
  const { isFullscreen } = useFullscreen();

  const settings = useSettings();
  const pageRef = React.useRef<HTMLDivElement>();

  const settingsProp: IChallengeSettings = {} as any;
  Object.keys(settingsParams).map((key) => {
    settingsProp[key] = settings.get(settingsParams[key]);
  });

  const { participant, userType, challengeData } = props;

  /*
    We show a Participant page is case of:
      - this is regular Participant Page;
      - this is Editor and owner work with settings for Participant page;
      - this is preview of Participant Page from BM.
    Also we need to force show Visitor page in case of owner wants to preview it from BM.
   */
  const isParticipantPage_ = isParticipantPage({
    participant,
    userType,
  });
  const isEditorPreview =
    settingsProp.editorParticipantRole === RolesState.Participant &&
    (isEditor || isPreview);
  const renderParticipantPage =
    (isParticipantPage_ ||
      isEditorPreview ||
      isForcedPreviewParticipant(props.query)) &&
    !isForcedPreviewVisitor(props.query);

  React.useEffect(() => {
    props.hideLoader && props.hideLoader();
  }, []);

  if (!challengeData?.challenge && isViewer) {
    if (userType === UserState.VISITOR) {
      props.promptLogin();
    }

    return <p className={classes.noAccess}>{t('challenge.no-access')}</p>;
  }

  return (
    <div ref={pageRef}>
      <div id="modal-root" />
      <div id="toast-root" />
      {renderParticipantPage ? ( // todo move it to separate file "JoinedParticipantPage"
        <>
          {settingsProp.participantLayout === ParticipantLayout.SIDEBAR ? (
            <LeaveChallengeModalProvider showModal={null}>
              <SidebarLayoutForParticipant
                {...props}
                isEditor={isEditor}
                isMobile={isMobile}
                t={t}
                pageRef={pageRef}
                settings={settingsProp as any}
                lng={language}
                isFullscreen={isFullscreen}
              />
            </LeaveChallengeModalProvider>
          ) : (
            <ListLayoutForParticipant
              lng={language}
              t={t}
              {...props}
              isMobile={isMobile}
              settings={settingsProp as any}
              isPreview={isPreview}
              isEditor={isEditor}
            />
          )}
          <ChallengeCompletedModal />
        </>
      ) : (
        <VisitorPage
          t={t}
          lng={language}
          {...props}
          settings={settingsProp as any}
          isMobile={isMobile}
        />
      )}
    </div>
  );
};

ChallengePage.displayName = 'ChallengePageContainer';

export const ChallengePageWithProviders: React.FC<any> = applyProviders(
  ChallengePage,
  [
    // withProviders,
    withBI,
    withInviteLink,
    withLocation,
    withSettingsEvents,
    withUser,
    withToast,
    withGeneralData,
    withChallengeData,
    withParticipantSections,
    withParticipantStepsData,
    withResolveStepData,
    withLoadingAPI,
    withPaidPlans,
  ],
);

export default Routing;
// export default ChallengePageWithProviders;
