import React from 'react';

import { ReactComponent as TrophyIcon } from '../../assets/icons/trophy.svg';

import { st, classes } from './RewardInfo.st.css';

import {
  ChallengeReward,
  Trigger,
} from '@wix/ambassador-challenge-service-web/types';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { Challenges } from '../../editor/types/Experiments';
import { useChallengeData } from '../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { isSelfPaced } from '../../selectors/challenges';

export interface IRewardInfoProps {
  rewards: ChallengeReward[];
  className?: string;
  icon?: boolean;
}

const REWARD_TRANS_MAP = {
  [Trigger.ALL_STEPS_COMPLETED]: 'rewards.completed-all-steps',
  [Trigger.STEP_COMPLETED]: 'rewards.completed-first-step',
  [Trigger.JOINED_TO_CHALLENGE]: 'rewards.joined-challenge',
  ALL_STEPS_COMPLETED_SPC: 'rewards.completed-all-steps-spc',
};

export const RewardInfo: React.FC<IRewardInfoProps> = (props) => {
  const { experiments } = useExperiments();
  const { t } = useTranslation();
  const { challengeData } = useChallengeData();
  if (!props.rewards.length) {
    return null;
  }

  const isSPC = isSelfPaced(challengeData.challenge);

  const rewardText =
    isSPC && experiments.enabled(Challenges.enableBadgeRewardForSPC)
      ? REWARD_TRANS_MAP.ALL_STEPS_COMPLETED_SPC
      : REWARD_TRANS_MAP[props.rewards[0].trigger];

  return (
    <div className={st(classes.root, {}, props.className)}>
      {!!props.icon && (
        <span className={classes.icon}>
          <TrophyIcon />
        </span>
      )}
      <span className={classes.text}>{t(rewardText)}</span>
    </div>
  );
};
