import React from 'react';

import { classes } from './OverviewIcon.st.css';

export const OverviewIcon: React.FC = () => {
  return (
    <div className={classes.root}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g className={classes.main} fillRule="evenodd">
          <g transform="translate(11.111 7.556)" fill="currentColor">
            <path d="M0 2.91h1.673v4.247l.509.025V8H0v-.785l.197-.018a.41.41 0 00.373-.409c.007-.03.007-.96 0-2.787-.002-.157-.07-.236-.208-.236H0v-.856z"></path>
            <circle cx="0.909" cy="0.909" r="1"></circle>
          </g>
          <path
            fillRule="nonzero"
            d="M12 20a8 8 0 100-16 8 8 0 000 16zm0-.889a7.111 7.111 0 110-14.223 7.111 7.111 0 010 14.223z"
          ></path>
        </g>
      </svg>
    </div>
  );
};
