import {
  IUserType,
  UserState,
} from '../../../../../../contexts/User/UserContext';
import { ParticipantState } from '@wix/ambassador-challenge-service-web/types';
import { TranslateFunction } from '@wix/challenges-web-library/dist/src';

interface IGetJoinButtonTextProps {
  isAvailableForJoinImmediately: boolean;
  userType: IUserType;
  t: TranslateFunction;
}

export function getJoinButtonText(args: IGetJoinButtonTextProps): string {
  const { isAvailableForJoinImmediately, userType, t } = args;

  switch (userType) {
    case UserState.VISITOR:
    case UserState.MEMBER:
    case ParticipantState.JOIN_REJECTED:
    case ParticipantState.LEFT:
    case ParticipantState.REMOVED:
    case ParticipantState.COMPLETED:
    case ParticipantState.FAILED:
      return isAvailableForJoinImmediately
        ? t('challenge.page.join')
        : t('challenge.page.request-to-join');
    case ParticipantState.JOINED:
    case ParticipantState.RUNNING:
      return t('challenge.page.join-via', {
        wixApp: t('challenge.wix-app'),
      });
    case ParticipantState.JOIN_REQUESTED:
      return t('challenge.join-request.cancel');
    case ParticipantState.INVITED:
    case ParticipantState.PAYMENT_REQUESTED:
    case ParticipantState.PAYMENT_STARTED:
    default:
      return t('challenge.page.join');
  }
}
