import { V1Participant } from '@wix/ambassador-challenge-service-web/types';

export function getCompletionProgress(participant: V1Participant) {
  const stepsSummary = participant?.stepsSummary;
  if (!stepsSummary) {
    return 0;
  }
  return Math.floor(
    (stepsSummary.completedStepsNumber / stepsSummary.stepsNumber) * 100,
  );
}
