import React from 'react';

import { StepsListAsTiles } from '../StepsListAsTiles';

import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import { convertTextToAccordionAlignment } from '../../../../../services/settingsHelpers';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { SectionsAsTilesAccordion } from '../../../../../components-shared/SectionsAsTilesAccordion';

import { st, classes } from './SectionsListAsTiles.st.css';
import { isStepResolved } from '../../views/utils';
import { useSections } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { Challenges } from '../../../../../editor/types/Experiments';
import {
  getSectionIcon,
  isSectionLocked,
} from '../../../../../selectors/sections';
import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';

export interface ISectionsListAsTilesProps {
  currentStepId: string;
  onSectionChosen?: Function;
  chosenSectionTileId?: string;
  onStepChosen(step: V1ParticipantStep): void;
}

export const SectionsListAsTiles: React.FunctionComponent<ISectionsListAsTilesProps> =
  (props: ISectionsListAsTilesProps) => {
    const { onSectionChosen, chosenSectionTileId } = props;
    const settings = useSettings();
    const { t } = useTranslation();
    const { isMobile } = useEnvironment();
    const { experiments } = useExperiments();
    const {
      isListParticipantSectionsRequestInProgress,
      listParticipantSections = [],
    } = useSections();

    if (isListParticipantSectionsRequestInProgress) {
      // TODO: add spinner;
      return null;
    }

    return (
      <div
        className={`${st(classes.root, {
          mobile: isMobile,
          contentTextAlignment: settings.get(
            challengeSettings.listLayoutContentAlignment,
          ),
        })}`}
      >
        <ul className={classes.sectionsList}>
          {listParticipantSections.map((section, ind: number) => {
            const steps = section.steps;
            const sectionSettings = section.source.settings;
            const key = `section-${section.id}`;
            const sectionSteps = steps || [];
            const resolvedLength = sectionSteps.filter((step) =>
              isStepResolved(step),
            ).length;
            const isOpenSection = !!steps.find(
              (step) => step?.id === props.currentStepId,
            );
            const subtitle = t('live.challenges-page.section-steps-subtitle', {
              resolved: resolvedLength,
              total: sectionSteps.length,
            });

            const isActiveSection = chosenSectionTileId === section.id;
            const isHideSection =
              experiments.enabled(Challenges.enableDripContent) &&
              isSectionLocked(section);

            const Icon = getSectionIcon(section);

            return (
              <li key={`section-${ind}`} className={classes.sectionsListItem}>
                <SectionsAsTilesAccordion
                  prefixIcon={<Icon />}
                  id={key}
                  key={key}
                  className={isActiveSection ? classes.sectionActive : ''}
                  opened={isOpenSection || false}
                  title={sectionSettings?.description?.title}
                  subtitle={subtitle}
                  emptyChildren={!!steps.length}
                  align={convertTextToAccordionAlignment(
                    settings.get(challengeSettings.sidebarLayoutTextAlignment),
                  )}
                  onClick={() => {
                    if (onSectionChosen) {
                      onSectionChosen(section.id);
                    }
                  }}
                >
                  {!isHideSection && (
                    <StepsListAsTiles
                      isSection={true}
                      currentStepId={props.currentStepId}
                      steps={sectionSteps}
                      isSPC={true}
                      onStepChosen={props.onStepChosen}
                    />
                  )}
                </SectionsAsTilesAccordion>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
SectionsListAsTiles.displayName = 'SectionsListAsTiles';
