import React from 'react';

import { V1Challenge } from '@wix/ambassador-challenge-service-web/types';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { GeneralDataContext } from '../../../../../contexts/GeneralDataProvider/GeneralDataContext';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { covertTextToBadgesAlignment } from '../../../../../services/settingsHelpers';

import { serverTimelineTypeToClientTimelineString } from '@wix/challenges-web-library/dist/src';

import { ChallengeOverviewSection } from '../ChallengeOverviewSection';
import { ChallengeDetails } from '../../../../../components-shared/ChallengeDetails';
import { SocialGroup } from '../../../../../components-shared/SocialGroup';
import { AwardsBadges } from '../../../../../components-shared/Badges';
import UserBox from '../../../../../components-shared/UserBox';
import { MediaCover } from '../../../../../components-shared/MediaCover';
import { RewardInfo } from '../../../../../components-shared/RewardInfo';
import { ChallengeStats } from '../ChallengeStats';

import { classes, st } from './ChallengeOverview.st.css';
import utils from '../../views/utils';
import settingsParams from '../../../settingsParams';
import { ListLayoutButton } from '../Buttons/ListLayoutButton/ListLayoutButton';
import { SidebarLayoutButton } from '../Buttons/SidebarLayoutButton/SidebarLayoutButton';
import { V2Badge } from '@wix/ambassador-members-badges-server/types';

export interface IChallengeOverviewProps {
  className?: string;
  type: 'Sidebar' | 'List';
  challenge: V1Challenge;
  badges: V2Badge[];
  isGroupInstalled: boolean;
  prefix?: React.ReactElement;
  goToCurrentStep?(): void;
  bottomControls?: React.ReactNode;
}

const Duration: React.FunctionComponent<Partial<IChallengeOverviewProps>> = ({
  challenge,
}) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { language } = useEnvironment();

  const { showHeaderDate } = utils.getBaseViewOptionsForSidebar(
    challenge,
    settings,
  );

  const durationString = serverTimelineTypeToClientTimelineString(
    challenge?.settings?.timelineType as any,
    language,
    t,
    'challenge-card.duration-string.ongoing',
    `challenge-card.duration-string.flexible.days_icu`,
    `challenge-card.duration-string.flexible.weeks_icu`,
    'challenge-card.duration-string.no-limit',
  );

  return showHeaderDate ? (
    <p className={classes.duration}>{durationString}</p>
  ) : null;
};

const OverviewMedia: React.FunctionComponent<Partial<IChallengeOverviewProps>> =
  ({ challenge }) => {
    const isShouldBeRendered = challenge?.settings?.description?.media;

    return isShouldBeRendered ? (
      <MediaCover
        fit="contain"
        maxHeight={500}
        media={challenge.settings.description.media}
      />
    ) : null;
  };

const Details: React.FunctionComponent<Partial<IChallengeOverviewProps>> = ({
  challenge,
}) => {
  const { isMobile } = useEnvironment();

  return (
    <ChallengeDetails
      isMobile={isMobile}
      className={classes.challengeDetails}
      details={challenge?.settings?.description?.details}
    />
  );
};

const Group: React.FunctionComponent<
  Partial<IChallengeOverviewProps> & { Button?: React.FC }
> = ({ challenge, isGroupInstalled, Button }) => {
  const { t } = useTranslation();
  const { instance } = React.useContext(GeneralDataContext);

  return (
    <SocialGroup
      Button={Button}
      isGroupInstalled={isGroupInstalled}
      instance={instance}
      title={t('live-site.groups.section-title')}
      subTitle={t('live-site.groups.section-subtitle.joined')}
      groupId={challenge?.settings?.socialGroupId}
      showButton={true}
    />
  );
};

const Awards: React.FunctionComponent<Partial<IChallengeOverviewProps>> = ({
  type,
  challenge,
  badges,
}) => {
  const { instance } = React.useContext(GeneralDataContext);
  const settings = useSettings();
  const alignment = covertTextToBadgesAlignment(
    utils.getContentAlignByType(type, settings),
  );

  return (
    <AwardsBadges
      badges={badges}
      alignment={alignment}
      rewards={challenge.settings.rewards}
      instance={instance}
    />
  );
};

const Reward: React.FunctionComponent<Partial<IChallengeOverviewProps>> = ({
  type,
  challenge,
}) => {
  return (
    <RewardInfo
      className={classes.rewardInfo}
      rewards={challenge.settings.rewards}
      icon={type === 'Sidebar'}
    />
  );
};

const Stats: React.FunctionComponent<Partial<IChallengeOverviewProps>> = ({
  type,
  challenge,
}) => {
  return (
    <ChallengeStats
      className={classes.challengeStats}
      type={type}
      challenge={challenge}
    />
  );
};

const User: React.FunctionComponent<Partial<IChallengeOverviewProps>> = ({
  type,
  challenge,
}) => {
  const settings = useSettings();

  return (
    <UserBox
      className={classes.userBox}
      imageUrl={challenge.owners[0].imageUrl}
      fullName={challenge.owners[0].fullName}
      dataHook="challenge-page-author"
      alignment={utils.getContentAlignByType(type, settings)}
    />
  );
};

export const ChallengeOverview: React.FunctionComponent<IChallengeOverviewProps> =
  ({
    className,
    prefix,
    type,
    challenge,
    isGroupInstalled,
    bottomControls,
    badges,
  }: IChallengeOverviewProps) => {
    const { t } = useTranslation();
    const { isMobile } = useEnvironment();
    const settings = useSettings();
    const alignment = utils.getContentAlignByType(type, settings);

    return (
      <div
        className={`${st(classes.root, {
          mobile: isMobile,
          type: type.toLowerCase(),
          alignment,
        })} ${className}`}
        data-hook="challenge-page-overview"
      >
        {prefix || null}

        {type === 'List' ? (
          <>
            <OverviewMedia challenge={challenge} />
            {!!challenge?.settings?.description?.details && (
              <ChallengeOverviewSection
                title={t('challenge.overview.about-title')}
                type={type}
              >
                <Details challenge={challenge} />
              </ChallengeOverviewSection>
            )}
            <ChallengeOverviewSection type={type}>
              <Group
                Button={ListLayoutButton}
                challenge={challenge}
                isGroupInstalled={isGroupInstalled}
              />
            </ChallengeOverviewSection>
            {challenge?.settings?.rewards?.length ? (
              <ChallengeOverviewSection
                type={type}
                title={t('challenge.overview.reward-title')}
              >
                <Awards type={type} challenge={challenge} badges={badges} />
                <Reward type={type} challenge={challenge} />
              </ChallengeOverviewSection>
            ) : null}

            {settings.get(settingsParams.displayOwner) ? (
              <ChallengeOverviewSection
                title={t('challenge.overview.owner-title')}
                type="List"
              >
                <User type={type} challenge={challenge} />
              </ChallengeOverviewSection>
            ) : null}
            {bottomControls && (
              <div className={classes.controlsContainer}>{bottomControls}</div>
            )}
          </>
        ) : null}

        {type === 'Sidebar' ? (
          <>
            <Duration challenge={challenge} />
            <Reward type={type} challenge={challenge} />
            <Stats type={type} challenge={challenge} />
            <OverviewMedia challenge={challenge} />
            {!!challenge?.settings?.description?.details && (
              <ChallengeOverviewSection
                type={type}
                title={t('live.challenges-page-sidebar.about-title')}
              >
                <Details challenge={challenge} />
              </ChallengeOverviewSection>
            )}
            <Group
              Button={SidebarLayoutButton}
              challenge={challenge}
              isGroupInstalled={isGroupInstalled}
            />
            {challenge?.settings?.rewards?.length ? (
              <ChallengeOverviewSection
                type={type}
                title={t('challenge.overview.reward-title')}
              >
                <Awards type={type} challenge={challenge} badges={badges} />
              </ChallengeOverviewSection>
            ) : null}
            {settings.get(settingsParams.displayOwner) ? (
              <ChallengeOverviewSection
                type={type}
                title={t('live.challenges-page-sidebar.owner-title')}
              >
                <User type={type} challenge={challenge} />
              </ChallengeOverviewSection>
            ) : null}
          </>
        ) : null}
      </div>
    );
  };
ChallengeOverview.displayName = 'ChallengeOverview';
ChallengeOverview.defaultProps = {
  className: '',
};
