import React from 'react';
import { IAccordionProps, Accordion } from '../Accordion';

import { st, classes } from './SectionsAccordion.st.css';

export interface ISectionsAccordionProps extends IAccordionProps {
  id?: string;
}

export const SectionsAccordion: React.FC<ISectionsAccordionProps> = (props) => {
  const { title, id, className, ...rest } = props;
  return (
    <Accordion
      id={id}
      title={title}
      className={st(classes.root, {}, className)}
      {...rest}
    >
      {props.children}
    </Accordion>
  );
};
