import { ButtonNames } from '../../../../../../contexts/BI/interfaces';
import { getChoiceRightness, isRightChoiceAnswer } from './fields';
import { FeedbackItemSettings } from '@wix/ambassador-challenge-service-web/types';
import qsUtils from '../utils';

const biFieldsStorage = {};

export const buildBIFunction = ({
  fieldId,
  isFieldRequired,
  buttonName,
  memberWebAppButtonClick,
  clearButtonName,
  isClearableField = false,
}: {
  fieldId: string;
  isFieldRequired: boolean;
  buttonName: ButtonNames;
  memberWebAppButtonClick: Function;
  clearButtonName?: ButtonNames;
  isClearableField?: boolean;
}): Function => {
  if (!biFieldsStorage[fieldId]) {
    let isLastValueFilled = false;

    biFieldsStorage[fieldId] = async (
      isValueFilled: boolean,
    ): Promise<void> => {
      let preventSendBI = false;

      if (isClearableField) {
        if (isValueFilled === isLastValueFilled) {
          preventSendBI = true;
        } else {
          isLastValueFilled = isValueFilled;
        }
      }

      if (!preventSendBI) {
        await memberWebAppButtonClick({
          buttonName:
            isClearableField && !isLastValueFilled
              ? clearButtonName
              : buttonName,
          isRequired: isFieldRequired,
        });
      }
    };
  }

  return biFieldsStorage[fieldId];
};

export const sendFieldsSnapshotBI = async (
  feedbackSettings: FeedbackItemSettings[],
  fieldValues: { [index: string]: any },
  stepId: string,
  quizCheckSnapshot: Function,
) => {
  const values = {};

  feedbackSettings.forEach((field) => {
    const fieldType = qsUtils.getFieldType(field);

    if (['multiSelect', 'multipleChoice'].includes(fieldType)) {
      const isMultiple = !!field.type?.multiSelect;
      const fieldValue = fieldValues[field.id];
      const fieldRightness = getChoiceRightness(
        fieldValue,
        field.type?.[isMultiple ? 'multiSelect' : 'multipleChoice']?.choices ||
          [],
        isMultiple,
      );
      const isRightAnswer = isRightChoiceAnswer(fieldRightness);

      values[field.id] = {
        value: fieldValue,
        isRightAnswer,
      };
    }
  });

  await quizCheckSnapshot(stepId, values);
};
