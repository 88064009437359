import { RicosTheme } from 'ricos-common/dist/src/themeStrategy/themeTypes';

const getParticularColorValue = (hostStyle, colorName) => {
  return hostStyle.siteColors.find(({ name }) => {
    return name === colorName;
  })?.value;
};

const getColorByKey = (
  hostStyle: any,
  colorKey: string,
  fallbackKey?: string,
) => {
  const textColorFromSettings =
    hostStyle?.styleParams?.colors?.[colorKey]?.value; // todo use it when the time will come
  return (
    textColorFromSettings ||
    getParticularColorValue(hostStyle, fallbackKey || 'color_15')
  );
};

const getCustomTheme = (font, textColor) => {
  const fontSize = font?.size || 0;

  const commonStyles = {
    fontFamily: font?.family,
    color: textColor,
  };

  return {
    h2: {
      fontSize: `${fontSize * 1.75 || 28}px`,
      ...commonStyles,
    },
    h3: {
      fontSize: `${fontSize * 1.375 || 22}px`,
      ...commonStyles,
    },
    h4: {
      fontSize: `${fontSize * 1 || 16}px`,
      ...commonStyles,
    },
    h5: {
      fontSize: `${fontSize * 0.83 || 14}px`,
      ...commonStyles,
    },
    h6: {
      fontSize: `${fontSize * 0.67 || 11}px`,
      ...commonStyles,
    },
    p: {
      fontSize: `${fontSize * 1 || 16}px`,
      ...commonStyles,
    },
    quote: {
      fontSize: `${fontSize * 1.125 || 18}px`,
      ...commonStyles,
    },
    link: {
      fontSize: `${fontSize * 1 || 16}px`,
      ...commonStyles,
    },
    hashtag: {
      fontSize: `${font?.size * 1 || 16}px`,
      ...commonStyles,
    },
  };
};

export function getTheme({
  host,
  fontKey,
  colorKey,
  bgColorKey,
}: {
  host: any;
  fontKey: string;
  colorKey: string;
  bgColorKey: string;
}): RicosTheme {
  if (!host) {
    return;
  }

  const textColor = getColorByKey(host.style, colorKey);
  const actionColor = getParticularColorValue(host.style, 'color_18');
  const bgColor = getColorByKey(host.style, bgColorKey, 'color_11');
  const font = host?.style?.styleParams?.fonts[fontKey];
  const customStyles = {
    ...getCustomTheme(font, textColor),
  };

  return {
    customStyles,
    palette: {
      textColor,
      bgColor,
      actionColor,
    },
  };
}
