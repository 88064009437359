import React from 'react';

interface ILocales {
  [index: string]: any;
}

export default function useLocales(): {
  locales: ILocales;
  isLocalesLoading: boolean;
} {
  const [locales, setLocales] = React.useState<ILocales>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    async function fetchLocales() {
      try {
        const fetchedLocales = await import('date-fns/locale');

        setLocales(fetchedLocales);
        setIsLoading(false);
      } catch (err) {
        console.error(err);

        setIsLoading(false);
      }
    }

    fetchLocales();
  }, []);

  return {
    locales,
    isLocalesLoading: isLoading,
  };
}
