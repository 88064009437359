import React from 'react';

import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { SettingsEventsContext } from '../../../../../contexts/SettingsEvents/SettingsEvents';
import { convertTextToAccordionAlignment } from '../../../../../services/settingsHelpers';
import challengeSettings from '../../../settingsParams';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { IsStepHiddenContext } from '../../../../../contexts/IsStepHidden/IsStepHiddenContext';
import { ChallengeDataContext } from '../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { isSelfPaced } from '../../../../../selectors/challenges';
import { ActionBlockerAppearance } from '../../../../../components-shared/ActionBlocker/ActionBlocker';
import { PreviewActionBlockerContainer } from '../../../../../components-shared/ActionBlocker/PreviewActionBlockerContainer';
import { useUser } from '../../../../../contexts/User/UserContext';
import useLocales from '../../../../../services/hooks/useLocales';
import format from 'date-fns/format';
import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';

import { StepsAccordion } from '../../../../../components-shared/StepsAccordion';
import { StepCompletedMessage } from '../StepCompletedMessage';
import { Ricos } from '../../../../../components-shared/Ricos';
import { StepViewHidden } from '../StepViewHidden';

import { classes, st } from './StepView.st.css';
import utils, {
  isParticipantCompletedChallenge,
  isStepAvailableForComplete,
  isStepOverdue,
  isStepResolved,
} from '../../views/utils';
import { ListLayoutButton } from '../Buttons/ListLayoutButton/ListLayoutButton';
import { StepIcon } from '../../../../../components-shared/StepIcon';
import { TextAlignment } from '../../../Settings/challengeSettings/challengeSettings.types';

export interface IStepViewProps {
  className?: string;

  step: V1ParticipantStep;
  isStepOpened: boolean;
  isStepForceOpened?: boolean;
  renderType?: 'AccordionTitle' | 'AccordionFull' | 'ClearStep';
  isLastStep?: boolean;

  isStepsLoading: boolean;
  isParticipantCompletedChallenge: boolean;

  isWeekFixForCTA?: boolean;

  onStepTitleClick?(id: string): void;

  onStepResolve?(step: V1ParticipantStep): void;

  onFeedbackView?(step: V1ParticipantStep): void;

  onShare?(step: V1ParticipantStep): void;

  onNextStepClick?(): void;

  goToCurrentStep?(): void;
}

const StepViewBody: React.FunctionComponent<IStepViewProps> = (
  props: IStepViewProps,
) => {
  const {
    step,
    isStepsLoading,
    isLastStep,
    isParticipantCompletedChallenge: isParticipantCompletedChallengeFromProps,
    onStepResolve,
    onFeedbackView,
    onShare,
    onNextStepClick,
  } = props;
  const { t } = useTranslation();
  const { language } = useEnvironment();
  const settings = useSettings();
  const { participant } = useUser();

  const isStepLocked =
    utils.isStepLocked(step) && !isParticipantCompletedChallenge(participant);
  const { isVisibleStepLockedForComplete } =
    React.useContext(IsStepHiddenContext);
  const isCompleteButtonShown =
    isStepAvailableForComplete(step) || isStepOverdue(step);

  const { locales, isLocalesLoading } = useLocales();

  if (isLocalesLoading) {
    return null;
  }

  const formattedStepDate = format(new Date(step.dateFrame.start), 'PP', {
    locale: locales[language],
  });

  const alignment = settings.get(challengeSettings.listLayoutContentAlignment);

  const CTAButton = isCompleteButtonShown ? (
    <PreviewActionBlockerContainer
      appearance={ActionBlockerAppearance.PopoverAppearance}
      content={t('action-blocker.preview-mode.complete-step')}
      isActionAllowed={utils.isFeedbackFormRequired(step)}
    >
      <ListLayoutButton
        className={classes.completeStepAction}
        onClick={() => {
          if (!isStepsLoading) {
            onStepResolve(step);
          }
        }}
      >
        {t('challenge.page.steps.complete')}
      </ListLayoutButton>
    </PreviewActionBlockerContainer>
  ) : (
    <ListLayoutButton
      className={classes.completeStepAction}
      onClick={onNextStepClick}
    >
      {isLastStep
        ? t('live.challenges-page.back-from-step-view')
        : t('challenge.page.next-step')}
    </ListLayoutButton>
  );

  return (
    <>
      {isStepLocked ? (
        <p
          className={`${classes.stepUnavailable} ${
            alignment === TextAlignment.Right
              ? classes.stepUnavailableRight
              : ''
          }`}
        >
          {t('challenge.page.step-unavailable.warning', {
            date: formattedStepDate,
          })}
        </p>
      ) : null}

      {isStepResolved(step) ? (
        <StepCompletedMessage
          t={t}
          step={step}
          isStepsLoading={isStepsLoading}
          onFeedbackView={onFeedbackView}
          onShare={onShare}
        />
      ) : null}

      <Ricos
        key={step?.id}
        details={step?.source?.settings?.general?.description?.details}
        contentId={step?.id}
        context="program_step"
      />

      {!isStepLocked &&
      !isVisibleStepLockedForComplete(step) &&
      !isParticipantCompletedChallengeFromProps
        ? CTAButton
        : null}
    </>
  );
};
StepViewBody.displayName = 'StepViewBody';

export const StepView: React.FunctionComponent<IStepViewProps> = (
  props: IStepViewProps,
) => {
  const {
    className,
    step,
    isStepOpened,
    isStepForceOpened,
    renderType,
    isWeekFixForCTA,
    onStepTitleClick,
    goToCurrentStep,
  } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { participant } = useUser();
  const { buttonState } = React.useContext(SettingsEventsContext);
  const settings = useSettings();
  const { isStepHidden, isVisibleStepLockedForComplete } =
    React.useContext(IsStepHiddenContext);
  const {
    challengeData: { challenge },
  } = React.useContext(ChallengeDataContext);

  React.useEffect(() => {
    utils.scrollToChallengePage();
  }, []);

  const title = step?.source?.settings?.general?.description?.title;

  const isHidden = isStepHidden(step);
  const isUnavailable = isHidden || isVisibleStepLockedForComplete(step);

  const tooltip = t(
    utils.getStepTooltipKey(
      step,
      isUnavailable,
      isSelfPaced(challenge),
      participant,
    ),
  );

  const isAccordion =
    renderType === 'AccordionTitle' || renderType === 'AccordionFull';
  const isAccordionTitleOnly = renderType === 'AccordionTitle';

  const tooltipDisabled =
    !isParticipantCompletedChallenge(participant) &&
    isStepAvailableForComplete(step) &&
    !isUnavailable;

  return (
    <div
      className={`${st(classes.root, {
        mobile: isMobile,
        contentTextAlignment: settings.get(
          challengeSettings.listLayoutContentAlignment,
        ),
        buttonState,
      })} ${className}`}
      onClick={(e) => {
        if (isAccordionTitleOnly && onStepTitleClick) {
          onStepTitleClick(step.id);

          e?.preventDefault();
          e?.stopPropagation();
        }
      }}
    >
      {isAccordion ? (
        <StepsAccordion
          id={`step-${step.id}`}
          key={`step-${step.id}`}
          opened={isStepOpened && !isAccordionTitleOnly}
          forceOpened={isStepForceOpened && !isAccordionTitleOnly}
          className={isAccordionTitleOnly ? classes.isAccordionTitleOnly : ''}
          title={title}
          tooltipText={tooltip}
          prefixIcon={
            <StepIcon
              step={step}
              isUnavailable={isUnavailable}
              participant={participant}
            />
          }
          align={convertTextToAccordionAlignment(
            settings.get(challengeSettings.listLayoutContentAlignment),
          )}
          tooltipDisabled={tooltipDisabled}
        >
          {isAccordionTitleOnly ? null : !isHidden ? (
            <StepViewBody {...props} />
          ) : (
            <StepViewHidden
              type="List"
              date={step?.dateFrame?.start}
              isWeekFixForCTA={isWeekFixForCTA}
              goToCurrentStep={goToCurrentStep}
            />
          )}
        </StepsAccordion>
      ) : !isHidden ? (
        <>
          <h3 className={classes.title}>{title}</h3>
          <StepViewBody {...props} />
        </>
      ) : (
        <StepViewHidden
          type="List"
          date={step?.dateFrame?.start}
          isWeekFixForCTA={isWeekFixForCTA}
          goToCurrentStep={goToCurrentStep}
        />
      )}
    </div>
  );
};
StepView.displayName = 'StepView';
StepView.defaultProps = {
  className: '',
  renderType: 'AccordionFull',
  isLastStep: false,
  onStepTitleClick: null,
  onStepResolve: () => {},
  onFeedbackView: () => {},
  onShare: () => {},
  onNextStepClick: () => {},
};
