import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Tooltip } from 'wix-ui-tpa/Tooltip';
import { useIsStepHidden } from '../../../../../contexts/IsStepHidden/IsStepHiddenContext';
import { useUser } from '../../../../../contexts/User/UserContext';
import utils, {
  isParticipantCompletedChallenge,
  isStepAvailableForComplete,
} from '../../views/utils';

import { st, classes } from './StepsListAsTiles.st.css';
import challengeSettings from '../../../settingsParams';
import { StepIcon } from '../../../../../components-shared/StepIcon';

export interface IStepSidebar {
  step: V1ParticipantStep;
  isSPC: boolean;
  currentStepId: string;
  onStepChosen: Function;
  className?: string;
}

export const StepSidebar: React.FC<IStepSidebar> = (props) => {
  const { t } = useTranslation();
  const { isSPC, currentStepId, onStepChosen, step } = props;
  const { isStepHidden, isVisibleStepLockedForComplete } = useIsStepHidden();
  const { participant } = useUser();
  const settings = useSettings();

  const isStepUnavailable =
    isStepHidden(step) || isVisibleStepLockedForComplete(step);
  const tooltip = t(
    utils.getStepTooltipKey(step, isStepUnavailable, isSPC, participant),
  );
  const isCurrent = step?.id === currentStepId;
  const tooltipDisabled =
    !isParticipantCompletedChallenge(participant) &&
    isStepAvailableForComplete(step) &&
    !isStepUnavailable;

  return (
    <li
      className={st(classes.stepItem, {}, isCurrent && classes.stepItemActive)}
      data-id={step?.id}
      key={step?.id}
      onClick={() => {
        onStepChosen(step);
      }}
    >
      <button
        className={st(classes.stepLink, {
          align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
        })}
        onClick={() => {}}
      >
        <Tooltip
          className={classes.stepItemIcon}
          content={tooltip}
          placement="top"
          appendTo="window"
          maxWidth={250}
          // do not show tooltip for available step
          disabled={tooltipDisabled}
        >
          <StepIcon
            step={step}
            isUnavailable={isStepUnavailable}
            participant={participant}
          />
        </Tooltip>
        <div className={classes.gapBetweenTitleAndIcon} />
        <p className={`${classes.stepItemTitle}`}>
          {step?.source?.settings?.general?.description?.title}
        </p>
      </button>
    </li>
  );
};

StepSidebar.displayName = 'StepSidebarItem';
