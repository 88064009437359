import React from 'react';
import { IChallengePageProps, IChallengePageState } from '../../interfaces';

import {
  IWixSDKContext,
  withExperiments,
  withBi,
  InjectedBiLoggerProps,
} from '@wix/yoshi-flow-editor';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import add from 'date-fns/add';
import { getChallengeWeekRangeForDate } from '../../../../../selectors/dates';
import { ButtonNames, TabNames } from '../../../../../contexts/BI/interfaces';

import {
  ParticipantStepState,
  V1ParticipantStep,
} from '@wix/ambassador-challenge-service-web/types';
import { isSelfPaced } from '../../../../../selectors/challenges';

import { Spinner } from '../../../../../components-shared/Spinner';
import { WeeksSelector } from '../../../../../components-shared/WeeksSelector';
import {
  ALIGNMENT as TabsALIGNMENT,
  SKIN as TabsSKIN,
  Tabs,
  VARIANT as TabsVARIANT,
} from 'wix-ui-tpa/Tabs';

import { SectionsList } from '../../components/SectionsList';
import { StepsList } from '../../components/StepsList';
import { StepView } from '../../components/StepView';
import { Back } from '../../components/icons/Back';
import { ChallengeNotification } from '../../components/ChallengeNotification';

import {
  convertToTabsAlignment,
  covertTextToWeekSelectorAlignment,
} from '../../../../../services/settingsHelpers';
import { ListLayoutSelectedTab } from '../../../Settings/tabs/Design/components/OverviewScheduleSwitcher/constants';

import { ChallengeOverview } from '../../components/ChallengeOverview';
import { ChallengeHeader } from '../../components/ChallengeHeader';
import { LeaveChallengeModal } from '../../../../../components-shared/LeaveChallengeModal';
import { ToastType } from '../../../../../contexts/ToastContext/interfaces';
import { isForcedPreviewParticipant } from '../../../../../selectors/isForcedPreview';

import { classes, st } from './ListLayoutForParticipant.st.css';
import '../../../../../components-shared/Ricos/rce/rce.global.scss';
import utils, {
  getFlatStepsList,
  getNextStepToComplete,
  isParticipantCompletedChallenge,
  isStepResolved,
} from '../utils';
import { ShareModal } from '../../components/ShareModal';
import { ChallengeQuestionnaire } from '../../components/ChallengeQuestionnaire';
import { getFirstAvailableStepFromSection } from '../../../../../selectors/sections';
import { ListLayoutButton } from '../../components/Buttons/ListLayoutButton/ListLayoutButton';
import { Challenges } from '../../../../../editor/types/Experiments';
import {
  memberWebAppButtonClick as memberWebAppButtonClickV2,
  memberWebAppTabClick,
} from '@wix/bi-logger-challenges-member-web/v2';

const SCHEDULE_TAB = 0;
const OVERVIEW_TAB = 1;

export interface IListLayoutExtend {
  isEditor: boolean;
  isPreview: boolean;
  isMobile: boolean;
  lng: string;
  experiments: any;
}
class ListLayoutForParticipant extends React.Component<
  IChallengePageProps &
    IWixSDKContext &
    IListLayoutExtend &
    InjectedBiLoggerProps,
  IChallengePageState
> {
  static displayName = 'ChallengePage';
  public readonly pageName = 'challenge-page';
  public readonly translationName = 'challenge.page';

  constructor(
    props: IChallengePageProps &
      IWixSDKContext &
      IListLayoutExtend &
      InjectedBiLoggerProps,
  ) {
    super(props);

    const { participant } = this.props;

    this.state = {
      isError: null,
      selectedTabIndex: 0,
      currentStep: null,
      isShareModalOpened: false,
      isFeedbackModalOpened: false,
      isLeaveModalOpened: false,
      selectedPaymentOption: null,
      isParticipantCompletedChallengeState:
        isParticipantCompletedChallenge(participant),
      sharedCurrentDateForWeeksSelector: null,
      stepViewAsPageId: null,
      sectionIdForceOpened: null,
      stepIdForceOpened: null,
      isCurrentStateWasResolved: false,
      savedQuestionnaireData: null,
    };
  }

  static getDerivedStateFromProps = (props) => {
    if (props.shownTab === ListLayoutSelectedTab.Schedule) {
      return {
        selectedTabIndex: SCHEDULE_TAB,
      } as IChallengePageState;
    }
    if (props.shownTab === ListLayoutSelectedTab.Overview) {
      return {
        selectedTabIndex: OVERVIEW_TAB,
      } as IChallengePageState;
    }
  };

  componentDidUpdate(prevProps) {
    const { t } = this.props;

    if (
      prevProps.isResolveStepRequestInProgress &&
      !this.props.isResolveStepRequestInProgress
    ) {
      if (!this.props.resolveStepError) {
        const currentStep = this.state.currentStep;

        this.props.showToast(
          t(
            !this.state.isCurrentStateWasResolved
              ? 'toast.step-completed'
              : 'toast.step-feedback-updated',
          ),
        );

        this.props.updateParticipantStepStatus(
          this.props.listParticipantSections,
          this.props.participantSteps?.steps,
          currentStep?.id,
          currentStep?.transitions,
          currentStep?.feedback,
        );
      } else {
        this.props.showToast(t('toast.step-completed-error'), {
          type: ToastType.error,
        });
      }
    }

    if (!prevProps.showJoinedToast && this.props.showJoinedToast) {
      this.props.showToast(
        t('toast.challenge-just-joined', {
          name: this.props.challengeData.challenge?.settings?.description
            ?.title,
        }),
      );
    }
  }

  sendBiButtonClick(buttonName: ButtonNames) {
    void this.props.bi.report(
      memberWebAppButtonClickV2({
        buttonName,
      }),
    );
  }

  getStepDataById(stepId): [V1ParticipantStep, boolean] {
    const {
      listParticipantSections = [],
      participantSteps: { steps = [] } = {},
    } = this.props;
    const isSections = listParticipantSections.length;
    const findStepById = (ss: V1ParticipantStep[]) => {
      return (ss || []).find((s) => s?.id === stepId);
    };

    if (!stepId) {
      return null;
    }

    let resultStep = null;
    let isLastStep = false;

    if (isSections) {
      listParticipantSections.forEach((section) => {
        if (!resultStep) {
          resultStep = findStepById(section.steps);
          isLastStep =
            (section.steps || []).lastIndexOf(resultStep) ===
            section.steps?.length - 1;
        }
      });
    } else {
      resultStep = findStepById(steps);
      isLastStep = (steps || []).lastIndexOf(resultStep) === steps?.length - 1;
    }

    return [resultStep, isLastStep];
  }

  isStepResolved = (step: V1ParticipantStep = null): boolean => {
    const currentStep = this.state?.currentStep;

    return (
      (step !== null ? step : currentStep)?.transitions?.['0']?.state ===
      ParticipantStepState.COMPLETED
    );
  };

  onFeedbackView = (step: V1ParticipantStep): void => {
    this.setState(
      {
        currentStep: step,
        isFeedbackModalOpened: true,
        savedQuestionnaireData: null,
      },
      () => {
        this.sendBiButtonClick(ButtonNames.SeeFeedback);
      },
    );
  };

  onShare = (step: V1ParticipantStep): void => {
    this.setState(
      {
        currentStep: step,
        isShareModalOpened: true,
      },
      () => {
        this.sendBiButtonClick(ButtonNames.ShareCompletedStep);
      },
    );
  };

  onStepResolve = (step: V1ParticipantStep): void => {
    this.setState(
      {
        currentStep: step,
        savedQuestionnaireData: null,
      },
      async () => {
        const isFeedbackFormRequired = utils.isFeedbackFormRequired(
          this.state.currentStep,
        );

        this.sendBiButtonClick(ButtonNames.StepComplete);

        if (isFeedbackFormRequired) {
          this.setState({
            isFeedbackModalOpened: true,
          });
        } else {
          this.resolveStep();
        }
      },
    );
  };

  resolveStep() {
    this.setState(
      {
        isFeedbackModalOpened: false,
      },
      async () => {
        const currentStep = this.state?.currentStep;
        const stepId = currentStep?.id;

        if (stepId) {
          const {
            isStepResolved: isCurrentStepResolved,
            currentStepAfterUpdate,
          } = utils.getResolveStepData(
            currentStep,
            this.state.savedQuestionnaireData,
          );

          this.setState(
            {
              currentStep: currentStepAfterUpdate,
              isCurrentStateWasResolved: isStepResolved(currentStep),
              savedQuestionnaireData: null,
            },
            async () => {
              await this.props.resolveStep(
                stepId,
                currentStepAfterUpdate?.feedback,
                isCurrentStepResolved,
              );
            },
          );
        } else {
          console.error("Can't find step for resolve.");
        }
      },
    );
  }

  isCurrentWeek = (): boolean => {
    const today = new Date();
    const { sharedCurrentDateForWeeksSelector } = this.state;

    return (
      !sharedCurrentDateForWeeksSelector ||
      isSameDay(today, sharedCurrentDateForWeeksSelector)
    );
  };

  /*
    Method updates weeks selector component and challenge step to the current week.
    Return true if its already current week.
   */
  updateScheduledViewToCurrentWeek = (): boolean => {
    const today = new Date();
    const isCurrentWeek = this.isCurrentWeek();

    if (!isCurrentWeek) {
      const { participant, updateParticipantSteps } = this.props;
      const range = getChallengeWeekRangeForDate(participant.dateFrame, today);

      updateParticipantSteps(range.from);

      this.setState({
        sharedCurrentDateForWeeksSelector: today,
      });
    }

    return !!isCurrentWeek;
  };

  /*
    Its needed to open the section / step and then clear props for ability to open it once more.
    The open method works inside `Accordion` component, when the prop changes from `false` to `true`.
    After that its scrolled to the opened step.
   */

  openCurrentStep = () => {
    const flatSteps = getFlatStepsList(this.props) || [];
    const isSections = !!this.props.listParticipantSections?.length;

    const stepId = isSections
      ? getFirstAvailableStepFromSection(this.props.listParticipantSections)?.id
      : utils.getFirstAvailableStep(flatSteps)?.id ||
        utils.getLastCompletedStep(flatSteps)?.id;

    this.openStep(stepId);
  };

  openStep = (stepId) => {
    const {
      challengeData: { challenge },
      listParticipantSections = [],
    } = this.props;
    const isSections = !!listParticipantSections.length;
    const sectionId = isSections
      ? listParticipantSections.find((section) =>
          (section.steps || []).map((step) => step.id).includes(stepId),
        )?.id
      : null;
    const isScheduled = !isSelfPaced(challenge);

    /*
      For scheduled challenges its needed to go to the current week,
        and, go to current step only if its current week already.
     */
    if (isScheduled) {
      const isCurrentWeekAlready = this.updateScheduledViewToCurrentWeek();

      if (!isCurrentWeekAlready) {
        // break the next flow if its not the current week
        return;
      }
    }

    this.setState(
      {
        sectionIdForceOpened: sectionId || null,
        stepIdForceOpened: stepId || null,
      },
      () => {
        this.cleanAfterStepOpened(stepId);
      },
    );
  };

  cleanAfterStepOpened = (stepId) => {
    this.setState(
      {
        sectionIdForceOpened: null,
        stepIdForceOpened: null,
      },
      () => {
        this.scrollToStep(stepId);
      },
    );
  };

  scrollToStep = (stepId) => {
    document.getElementById(`step-${stepId}`)?.parentElement?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  render() {
    const { challengeData, isLoading, settings } = this.props;

    return challengeData && challengeData.challenge ? (
      isLoading ? (
        <div className={classes.fullPageSpinner}>
          <Spinner data-hook={`${this.pageName}-spinner`} />
        </div>
      ) : (
        <main
          data-hook={this.pageName}
          className={st(classes.root, {
            mobile: this.isMobile(),
            headerTextAlignment: settings.listLayoutHeaderAlignment,
            contentTextAlignment: settings.listLayoutContentAlignment,
          })}
        >
          {this.renderWithChallenge()}
        </main>
      )
    ) : null;
  }

  renderContent() {
    const { challenge, badgesData } = this.props.challengeData;
    const isSPC = isSelfPaced(challenge);
    const currentTab = this.state.selectedTabIndex;
    const flatStepsList = getFlatStepsList(this.props);
    const progressPercentage = utils.getStepsResolvedValue(flatStepsList);
    const isScheduledChallengeWithSteps =
      !isSPC &&
      this.props.participantSteps?.steps?.length &&
      !this.props.isParticipantStepsLoading;

    if (currentTab === OVERVIEW_TAB) {
      return (
        <>
          <ChallengeOverview
            type="List"
            badges={badgesData?.badges ?? []}
            challenge={challenge}
            isGroupInstalled={this.props.isGroupsInstalled}
            bottomControls={
              this.props.experiments.enabled(
                Challenges.enableOverviewStartButton,
              ) &&
              !this.state.isParticipantCompletedChallengeState && (
                <ListLayoutButton
                  onClick={async () => {
                    this.setState({
                      selectedTabIndex: SCHEDULE_TAB,
                    });
                    if (isSPC) {
                      this.setState({
                        stepViewAsPageId:
                          getNextStepToComplete(flatStepsList)?.id,
                      });
                    } else {
                      this.openStep(getNextStepToComplete(flatStepsList)?.id);
                    }

                    this.sendBiButtonClick(ButtonNames.OverviewStartButton);
                  }}
                >
                  {progressPercentage === 0
                    ? this.props.t('challenge.page.overview.start-btn')
                    : this.props.t('challenge.page.overview.current-step')}
                </ListLayoutButton>
              )
            }
          />
        </>
      );
    }

    if (currentTab === SCHEDULE_TAB) {
      return (
        <>
          {!isSPC && this.renderWeeksSelector()}
          {this.renderStepsAndSections()}
          {!!isScheduledChallengeWithSteps && this.renderWeeksSelector()}
        </>
      );
    }

    return null;
  }

  renderStepsAndSections() {
    const { challenge } = this.props.challengeData;
    const isSPC = isSelfPaced(challenge);
    const stepViewAsPageId = this.state.stepViewAsPageId;
    const isSections = isSPC && this.props.listParticipantSections?.length;

    if (stepViewAsPageId) {
      return this.renderStepViewAsPage();
    }

    if (isSections) {
      return (
        <SectionsList
          sectionIdForceOpened={this.state.sectionIdForceOpened}
          isParticipantCompletedChallenge={
            this.state.isParticipantCompletedChallengeState
          }
          onStepTitleClick={(_stepViewAsPageId) => {
            this.setState({
              stepViewAsPageId: _stepViewAsPageId,
            });
          }}
          onNextStepClick={this.openCurrentStep}
        />
      );
    }

    return this.renderStepsList();
  }

  renderWithChallenge() {
    const { challenge } = this.props.challengeData;

    return (
      <>
        <ChallengeNotification // no notifications now, saved for future cases
          challengeJoinRestrictions={[]}
          type="List"
        />

        <ChallengeHeader
          challenge={challenge}
          isParticipantCompletedChallenge={
            this.state.isParticipantCompletedChallengeState
          }
          flatStepsList={getFlatStepsList(this.props)}
          isGroupsInstalled={this.props.isGroupsInstalled}
          tabs={this.renderTabs()}
          onLeave={() => {
            this.setState({
              isLeaveModalOpened: true,
            });
          }}
        />

        {this.renderContent()}

        <LeaveChallengeModal
          isOpen={this.state.isLeaveModalOpened}
          participantId={this.props?.participant?.id}
          onClose={() => {
            this.setState({
              isLeaveModalOpened: false,
            });
          }}
        />

        {this.state.currentStep ? (
          <ChallengeQuestionnaire
            isOpened={this.state.isFeedbackModalOpened}
            currentStep={this.state.currentStep}
            savedQuestionnaireData={this.state.savedQuestionnaireData}
            onSaveIntermediateData={(type, data) => {
              this.setState({
                savedQuestionnaireData: {
                  ...this.state.savedQuestionnaireData,
                  ...{
                    [type]: data || {},
                  },
                },
              });
            }}
            onResolve={() => {
              this.resolveStep();
            }}
            onCancel={() => {
              this.setState({
                isFeedbackModalOpened: false,
                savedQuestionnaireData: null,
              });
            }}
          />
        ) : null}

        {this.state.currentStep ? (
          <ShareModal
            step={this.state.currentStep}
            isOpened={this.state.isShareModalOpened}
            onClose={() => {
              this.setState({
                isShareModalOpened: false,
              });
            }}
          />
        ) : null}
      </>
    );
  }

  renderTabs() {
    const { t } = this.props;
    const isMobile = this.isMobile();

    return (
      <Tabs
        data-hook={`${this.pageName}-tabs`}
        className={classes.tabs}
        activeTabIndex={this.state.selectedTabIndex}
        skin={TabsSKIN.clear} // it has strange color in source code with opacity
        alignment={
          isMobile
            ? TabsALIGNMENT.center
            : convertToTabsAlignment(
                this.props.settings.listLayoutHeaderAlignment,
              )
        }
        variant={isMobile ? TabsVARIANT.fullWidth : TabsVARIANT.fit}
        items={[
          {
            title: t(`${this.translationName}.tabs.schedule`),
          },
          {
            title: t(`${this.translationName}.tabs.overview`),
          },
        ]}
        onTabClick={(selectedTabIndex) => {
          this.setState(
            {
              selectedTabIndex,
            },
            async () => {
              await this.props.bi.report(
                memberWebAppTabClick({
                  tabName:
                    selectedTabIndex === 0
                      ? TabNames.ChallengeParticipantSchedule
                      : TabNames.ChallengeParticipantOverview,
                }),
              );
            },
          );
        }}
      />
    );
  }

  renderWeeksSelector() {
    const { t, lng, participant, updateParticipantSteps, location } =
      this.props;
    const isPreviewFromBM = isForcedPreviewParticipant(location?.query);
    const isNeedToUseOwnerSteps =
      this.props.isEditor || this.props.isPreview || isPreviewFromBM;

    // todo: this is default values for the editor, need to create Ambassador mocks and move it there
    const start =
      participant?.dateFrame?.start ||
      (isNeedToUseOwnerSteps ? format(new Date(), 'yyyy-MM-dd') : null);

    const finish =
      participant?.dateFrame?.finish ||
      (isNeedToUseOwnerSteps
        ? `${format(
            add(new Date(), {
              years: 2,
            }),
            'yyyy',
          )}-01-01`
        : null);

    return (
      <div className={classes.weeksSelector}>
        <WeeksSelector
          t={t}
          align={covertTextToWeekSelectorAlignment(
            this.props.settings.listLayoutContentAlignment,
          )}
          locale={lng}
          min={start}
          max={finish}
          sharedCurrentDate={this.state.sharedCurrentDateForWeeksSelector}
          onChange={async (range) => {
            await updateParticipantSteps(range.from);
          }}
          onCurrentDateChange={(currentDate) => {
            this.setState({
              sharedCurrentDateForWeeksSelector: currentDate,
            });
          }}
        />
      </div>
    );
  }

  renderStepViewAsPage() {
    const { t } = this.props;
    const [currentStep, isLastStep] = this.getStepDataById(
      this.state.stepViewAsPageId,
    );

    return (
      <div className={classes.stepViewAsPage}>
        <button
          className={classes.stepViewAsPageBack}
          onClick={() => {
            this.setState({
              stepViewAsPageId: null,
            });
          }}
        >
          <Back />
          <span>{t('live.challenges-page.back-from-step-view')}</span>
        </button>

        <StepView
          step={currentStep}
          isStepOpened={true}
          isStepsLoading={false}
          isParticipantCompletedChallenge={
            this.state.isParticipantCompletedChallengeState
          }
          renderType="ClearStep"
          isLastStep={isLastStep}
          isWeekFixForCTA={!this.isCurrentWeek()}
          onStepResolve={this.onStepResolve}
          onFeedbackView={this.onFeedbackView}
          onShare={this.onShare}
          onNextStepClick={() => {
            const flatSteps = getFlatStepsList(this.props);
            const nextStep = utils.getNextStep(currentStep?.id, flatSteps);

            this.setState({
              stepViewAsPageId: isLastStep ? null : nextStep.id,
            });
          }}
          goToCurrentStep={() => {
            this.setState(
              {
                stepViewAsPageId: null,
              },
              () => {
                this.openCurrentStep();
              },
            );
          }}
        />
      </div>
    );
  }

  renderStepsList() {
    const {
      isParticipantStepsLoading,
      challengeData: { challenge },
    } = this.props;

    return isParticipantStepsLoading ? (
      <div className={classes.spinner}>
        <Spinner />
      </div>
    ) : (
      <StepsList
        className={classes.stepsList}
        isStepsLoading={isParticipantStepsLoading}
        steps={this.props.participantSteps?.steps || []}
        isSPC={isSelfPaced(challenge)}
        isParticipantCompletedChallenge={
          this.state.isParticipantCompletedChallengeState
        }
        isWeekFixForCTA={!this.isCurrentWeek()}
        stepIdForceOpened={this.state.stepIdForceOpened}
        onStepResolve={this.onStepResolve}
        onFeedbackView={this.onFeedbackView}
        onShare={this.onShare}
        goToCurrentStep={this.openCurrentStep}
        onNextStepClick={this.openCurrentStep}
      />
    );
  }

  isMobile() {
    return this.props.isMobile;
  }
}

export default withExperiments(withBi(ListLayoutForParticipant));
