import React from 'react';

import { classes } from './MissedIcon.st.css';

export const MissedIcon: React.FC = () => {
  return (
    <div className={classes.root}>
      <span className={classes.rootCircle}></span>
    </div>
  );
};
