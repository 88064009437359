import React from 'react';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { st, classes } from './Notification.st.css';

interface INotificationProps {
  content: string;
  alignment?: any;
  withErrorIcon?: boolean;
  dataHook?: string;
  className?: string;
  maxWidth?: number;
}

export const Notification: React.FC<INotificationProps> = (props) => {
  const { content, dataHook, withErrorIcon, alignment, className } = props;

  return (
    <div
      className={st(
        classes.root,
        {
          alignment,
        },
        className,
      )}
      data-hook={dataHook}
    >
      <div className={classes.inner}>
        {withErrorIcon ? <ErrorIcon className={classes.icon} /> : null}

        <span className={classes.content}>{content}</span>
      </div>
    </div>
  );
};
