import React from 'react';

import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { LocationContext } from '../../../../../contexts/Location/LocationContext';
import challengeSettings from '../../../settingsParams';
import { Challenges } from '../../../../../editor/types/Experiments';

import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';

import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import { ShareButton } from 'wix-ui-tpa/ShareButton';

import { st, classes } from './StepCompletedMessage.st.css';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import utils from '../../views/utils';

export interface IStepCompletedMessageProps {
  t: Function;
  className?: string;
  step: V1ParticipantStep;
  isStepsLoading: boolean;
  onFeedbackView(step: V1ParticipantStep): void;
  onShare(step: V1ParticipantStep): void;
}

export const StepCompletedMessage: React.FunctionComponent<IStepCompletedMessageProps> =
  (props: IStepCompletedMessageProps) => {
    const { t, className, step, isStepsLoading, onFeedbackView, onShare } =
      props;
    const { experiments } = useExperiments();
    const isShareExpEnabled = experiments.enabled(Challenges.showStepShare);

    const settings = useSettings();
    const { isMobile } = useEnvironment();
    const {
      location: { url },
    } = React.useContext(LocationContext);

    return (
      <p
        className={`${st(classes.root, {
          mobile: isMobile,
          contentTextAlignment: settings.get(
            challengeSettings.listLayoutContentAlignment,
          ),
        })} ${className || ''}`}
      >
        <span>
          {t('challenge.page.step.completed')}
          {utils.isFeedbackFormRequired(step) ? (
            <TextButton
              priority={TEXT_BUTTON_PRIORITY.link}
              className={classes.seeFeedback}
              onClick={() => {
                if (!isStepsLoading) {
                  onFeedbackView(step);
                }
              }}
            >
              {t('challenge.page.step.completed.see-feedback')}
            </TextButton>
          ) : null}
        </span>
        {isShareExpEnabled ? (
          <ShareButton
            aria-label={t('live.challenges-page.social-share.title')}
            className={classes.shareButton}
            shareData={{
              title: t('social-share.title'),
              url,
            }}
            onClick={(sharePromise) => {
              if (!sharePromise && !isStepsLoading) {
                onShare(step);
              }
            }}
            withIcon
          />
        ) : null}
      </p>
    );
  };
StepCompletedMessage.displayName = 'StepCompletedMessage';
