import React from 'react';
import format from 'date-fns/format';
import groupBy from 'lodash/groupBy';
import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';

import { classes } from './StepsListAsTiles.st.css';
import { getRightDateFromBackend } from '../../../../../selectors/dates';

import { StepSidebar } from './StepSidebar';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import useLocales from '../../../../../services/hooks/useLocales';

export interface IScheduledStepsList {
  steps: V1ParticipantStep[];
  isSPC: boolean;
  currentStepId: string;
  onStepChosen(step: V1ParticipantStep): void;
}

export const ScheduledStepsList: React.FC<IScheduledStepsList> = (props) => {
  const { locales, isLocalesLoading } = useLocales();
  const { language } = useEnvironment();
  const stepsByStartDate = groupBy(props.steps, (step) => {
    return step.dateFrame.start;
  });

  if (isLocalesLoading) {
    return null;
  }

  return (
    <ul className={classes.stepsList}>
      {Object.keys(stepsByStartDate).map((startDate) => {
        const formattedStepDate = format(
          getRightDateFromBackend(startDate),
          'EEEE, PP',
          { locale: locales[language] },
        );

        return (
          <>
            <li className={`${classes.stepsTitle}`}>{formattedStepDate}</li>

            {stepsByStartDate[startDate].map((step, ind: number) => {
              return (
                <StepSidebar
                  key={ind}
                  step={step}
                  isSPC={props.isSPC}
                  currentStepId={props.currentStepId}
                  onStepChosen={props.onStepChosen}
                />
              );
            })}
          </>
        );
      })}
    </ul>
  );
};
