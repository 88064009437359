import React from 'react';
import { IAccordionProps, Accordion } from '../Accordion';

import { st, classes } from './StepsAccordion.st.css';

export interface IStepsAccordionProps extends IAccordionProps {
  id?: string;
}

export const StepsAccordion: React.FC<IStepsAccordionProps> = (props) => {
  const { title, id, className, ...rest } = props;

  return (
    <Accordion
      id={id}
      title={title}
      className={st(classes.root, {}, className)}
      {...rest}
    >
      {props.children}
    </Accordion>
  );
};
