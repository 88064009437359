import React from 'react';
import {
  ParticipantSection,
  V1ParticipantStep,
} from '@wix/ambassador-challenge-service-web/types';
import { CompletedIcon } from '../components-shared/CompletedIcon';
import { RunningIcon } from '../components-shared/RunningIcon';
import {
  getFirstAvailableStep,
  isStepResolved,
} from '../components/ChallengesPage/Widget/views/utils';
import { ReactComponent as LockedIcon } from '../assets/icons/locked.svg';

const STEP_INDICATOR: Record<'completed' | 'locked' | 'running', React.FC> = {
  completed: CompletedIcon,
  locked: LockedIcon,
  running: RunningIcon,
};

export const isSectionLocked = (section: ParticipantSection): boolean => {
  return !!section.transitions[0]?.waitingDate;
};

export const getFirstAvailableStepFromSection = (
  sections: ParticipantSection[] = [],
): V1ParticipantStep => {
  let step: V1ParticipantStep;

  sections.forEach((section) => {
    if (step || isSectionLocked(section)) {
      return;
    }

    step = getFirstAvailableStep(section.steps);
  });

  return step;
};

const isSectionCompleted = (steps: V1ParticipantStep[]): boolean => {
  return steps.every((step) => isStepResolved(step));
};

export const getSectionIcon = (section: ParticipantSection): React.FC => {
  if (isSectionCompleted(section.steps)) {
    return STEP_INDICATOR.completed;
  }

  if (section.transitions[0]?.waitingDate) {
    return STEP_INDICATOR.locked;
  }

  return STEP_INDICATOR.running;
};
