import React from 'react';

import {
  useTranslation,
  useExperiments,
  useEnvironment,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { useChallengeData } from '../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { useLocation } from '../../../../../contexts/Location/LocationContext';
import { Challenges } from '../../../../../editor/types/Experiments';

import { SocialShare } from '../SocialShare';
import { st, classes } from './ChallengeShare.st.css';
import challengeSettings from '../../../settingsParams';

export const ChallengeShare: React.FC = () => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { challengeData: { challenge = {} } = {} } = useChallengeData();
  const { location: { url = '' } = {} } = useLocation();

  return experiments.enabled(Challenges.enableShareChallenge) ? (
    <SocialShare
      className={st(classes.root, {
        align: settings.get(challengeSettings.contentTextAlignment),
        mobile: isMobile,
      })}
      shareTitle={t('live.challenges-page.social-share-challenge.share-title', {
        challengeName: challenge?.settings.description.title.trim(),
      })}
      shareUrl={url}
      originalUrl={url}
    />
  ) : null;
};
