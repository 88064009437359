import React from 'react';

import { TextAlignment } from '../../components/ChallengesPage/Settings/challengeSettings/challengeSettings.types';
import { DatePickerInput } from 'wix-ui-tpa/DatePickerInput';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { getDateFromString } from '../../selectors/dates';
import { st, classes } from './DateInput.st.css';
import { isAfter, isSameDay } from 'date-fns';
import useLocales from '../../services/hooks/useLocales';

export interface IDateInputProps {
  className?: string;
  dataHook?: string;
  defaultValue?: any;
  errorMessage?: string;
  onChange?(value: string): void;
  textAlignment: TextAlignment;
  showError?: boolean;
  minDate?: Date;
}

export const DateInput: React.FC<IDateInputProps> = (props) => {
  const { language } = useEnvironment();
  const currValue = getDateFromString(props.defaultValue);
  const { locales, isLocalesLoading } = useLocales();

  if (isLocalesLoading) {
    return null;
  }
  const locale = locales[language];

  return (
    <DatePickerInput
      className={st(
        classes.root,
        {
          textAlignment: props.textAlignment,
        },
        props.className,
      )}
      dateFormat={
        locale ? locale.formatLong.date({ width: 'short' }) : undefined
      }
      data-hook={props.dataHook}
      dateInputAriaLabelledby="date-input"
      hasError={props.showError}
      locale={locales[language]}
      value={currValue}
      onChange={props.onChange}
      errorMessage={props.errorMessage}
      excludePastDates={!props.minDate}
      filterDate={
        props.minDate
          ? (date) => {
              return (
                isSameDay(date, props.minDate) || isAfter(date, props.minDate)
              );
            }
          : null
      }
    />
  );
};
