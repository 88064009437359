import React from 'react';
import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Modal } from '../../../../../components-shared/Modal';
import { useChallengeData } from '../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { SocialShare } from '../SocialShare';

import { classes } from './ShareModal.st.css';
import { useLocation } from '../../../../../contexts/Location/LocationContext';

export interface IShareModal {
  isOpened: boolean;
  step: V1ParticipantStep;
  onClose(): void;
}

export const ShareModal: React.FC<IShareModal> = (props) => {
  const { t } = useTranslation();
  const {
    challengeData: { challenge = {} },
  } = useChallengeData();
  const {
    location: { url = '' },
  } = useLocation();

  const shareTitle = t('live.challenges-page.social-share.share-title', {
    stepName: props.step?.source.settings.general.description.title.trim(),
    challengeName: challenge?.settings.description.title.trim(),
  });

  const shareUrl = `${url}${
    !url.includes('?') ? '?' : '&'
  }shareTitle=${encodeURIComponent(shareTitle)}`;

  return (
    <Modal
      className={classes.socialShare}
      opened={props.isOpened}
      onClose={props.onClose}
    >
      <SocialShare
        shareTitle={shareTitle}
        shareUrl={shareUrl}
        originalUrl={url}
      />
    </Modal>
  );
};
