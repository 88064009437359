import {
  ChallengeReward,
  Trigger,
  V1Participant,
} from '@wix/ambassador-challenge-service-web/types';
import { V2Badge } from '@wix/ambassador-members-badges-server/types';

function isRewardEarned(
  trigger: Trigger,
  stepsNumber: number,
  completedStepsNumber: number,
): boolean {
  switch (trigger) {
    case Trigger.ALL_STEPS_COMPLETED:
      return stepsNumber === completedStepsNumber;
    case Trigger.JOINED_TO_CHALLENGE:
      return true;
    case Trigger.STEP_COMPLETED:
      return completedStepsNumber > 0;
    default:
      return false;
  }
}

function processBadgesWithRewards(
  rewards: ChallengeReward[],
  badges: V2Badge[],
  stepsNumber: number,
  completedStepsNumber: number,
): V2Badge[] {
  const badgesMap = new Map();
  const badgesResult = new Set();

  badges.forEach((b) => badgesMap.set(b.id, b));

  rewards.forEach(({ trigger, badgeIds }) => {
    const toBeAdded = isRewardEarned(
      trigger,
      stepsNumber,
      completedStepsNumber,
    );

    if (toBeAdded) {
      badgeIds?.forEach((id) => {
        badgesResult.add(badgesMap.get(id));
      });
    }
  });

  return Array.from(badgesResult.values());
}

export function getEarnedRewards(
  rewards?: ChallengeReward[],
  participant?: V1Participant,
  badges?: V2Badge[],
  isServerlessExperimentEnabled?: boolean,
): {
  rewards: ChallengeReward[];
  badges: V2Badge[];
} {
  const stepsSummary = participant?.stepsSummary;
  if (!rewards.length || !stepsSummary) {
    return { rewards: [], badges: [] };
  }
  const { stepsNumber, completedStepsNumber } = stepsSummary;

  const filteredRewards = rewards.filter(({ trigger }) =>
    isRewardEarned(trigger, stepsNumber, completedStepsNumber),
  );

  if (isServerlessExperimentEnabled) {
    return {
      rewards: filteredRewards,
      badges: processBadgesWithRewards(
        rewards,
        badges,
        stepsNumber,
        completedStepsNumber,
      ),
    };
  }

  return {
    rewards: filteredRewards,
    badges: [],
  };
}
