import React from 'react';

import { st, classes } from './ProgressBar.st.css';
import { TextAlignment } from '../../components/ChallengesPage/Settings/challengeSettings/challengeSettings.types';

export interface IProgressBarProps {
  value: number;
  label?: string;
  className?: string;
  'aria-describedby'?: string;
  align: TextAlignment;
  min?: number;
  max?: number;
}

export const ProgressBar: React.FC<IProgressBarProps> = (props) => {
  return (
    <div
      className={st(
        classes.root,
        {
          align: props.align,
        },
        props.className,
      )}
      role="progressbar"
      aria-valuemin={props.min || 0}
      aria-valuemax={props.max || 100}
      aria-valuenow={props.value}
      aria-describedby={props['aria-describedby']}
    >
      <div className={classes.progress}>
        <div className={classes.bar} style={{ width: `${props.value}%` }} />
      </div>
      <div className={classes.gapBetweenProgressAndLabel} />
      {props.label && <p className={classes.label}>{props.label}</p>}
    </div>
  );
};
