import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { classes } from './StepControls.st.css';
import { SidebarControlsContainer } from '../SidebarControlsContainer/SidebarControlsContainer';

export const FinishedNotification: React.FC = () => {
  const { t } = useTranslation();
  return (
    <SidebarControlsContainer>
      <p className={classes.finishedNotification}>
        {t('challenge.page.restrictions.finished-already')}
      </p>
    </SidebarControlsContainer>
  );
};
