import React from 'react';

import {
  RadioButton as CoreRadio,
  RadioButtonProps,
} from 'wix-ui-core/radio-button';

import { st, classes } from './RadioButton.st.css';
import { UnselectedIcon } from './Unselected';
import { SelectedIcon } from './Selected';

export interface IRadioButton extends RadioButtonProps {
  hideControl?: boolean;
  iconURL?: string;
  className?: string;
}

export const RadioButton: React.FC<IRadioButton> = (props) => {
  const spanRef = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    const input = spanRef?.current?.querySelector('[role=radio]');
    if (input) {
      input.setAttribute('role', 'none');
    }
  }, []);
  const {
    checked,
    disabled,
    className,
    hideControl = false,
    iconURL,
    ...rest
  } = props;

  const checkedIcon = iconURL ? (
    <span
      className={classes.customIcon}
      style={{
        backgroundImage: `url(${iconURL})`,
      }}
    />
  ) : !hideControl ? (
    <SelectedIcon />
  ) : null;

  const uncheckedIcon = iconURL ? (
    <span
      className={classes.customIcon}
      style={{
        backgroundImage: `url(${iconURL})`,
      }}
    />
  ) : !hideControl ? (
    <UnselectedIcon />
  ) : null;

  return (
    <span className={className} ref={spanRef}>
      <CoreRadio
        className={st(classes.icon, { checked, disabled })}
        checked={checked}
        checkedIcon={checkedIcon}
        uncheckedIcon={uncheckedIcon}
        {...rest}
      />
    </span>
  );
};
