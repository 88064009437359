import React from 'react';

import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';

import { isSelfPaced } from '../../../../../selectors/challenges';
import { ButtonNames } from '../../../../../contexts/BI/interfaces';
import { Challenges } from '../../../../../editor/types/Experiments';

import { Spinner } from '../../../../../components-shared/Spinner';

import { StepsListAsTiles } from '../../components/StepsListAsTiles';

import { IChallengePageProps, IChallengePageState } from '../../interfaces';
import {
  IWixSDKContext,
  withExperiments,
  withBi,
  InjectedBiLoggerProps,
} from '@wix/yoshi-flow-editor';

import { ShareButton } from 'wix-ui-tpa/ShareButton';
import { ListLayoutSelectedTab } from '../../../Settings/tabs/Design/components/OverviewScheduleSwitcher/constants';

import { SectionsListAsTiles } from '../../components/SectionsListAsTiles';
import { ChallengeOverview } from '../../components/ChallengeOverview';
import { StepViewBreadcrumbs } from '../../components/StepViewBreadcrumbs';
import { ToastType } from '../../../../../contexts/ToastContext/interfaces';
import { ChallengeNotification } from '../../components/ChallengeNotification';

import { classes, st } from './SidebarLayoutForParticipant.st.css';
import '../../../../../components-shared/Ricos/rce/rce.global.scss';
import utils, {
  ChallengeEntityType,
  getFlatStepsList,
  getNextStepToComplete,
  INextEntity,
  isParticipantCompletedChallenge,
  isStepResolved,
} from '../utils';
import {
  getWixAdvertisementBannerHeight,
  setElementToViewport,
} from '../../../../../services/scrollHelpers';
import { SideBarStepView } from '../../components/SideBarStepView';
import { OverviewSidebar } from '../../components/OverviewSidebar';
import { ChallengeSummary } from '../../components/ChallengeSummary/ChallengeSummary';
import {
  StepControls,
  SectionControls,
  FinishedNotification,
} from '../../components/StepControls';
import { ShareModal } from '../../components/ShareModal';
import { ChallengeQuestionnaire } from '../../components/ChallengeQuestionnaire';
import { SidebarSectionView } from '../../components/SidebarSectionView';
import { getFirstAvailableStepFromSection } from '../../../../../selectors/sections';
import { SidebarControlsContainer } from '../../components/SidebarControlsContainer/SidebarControlsContainer';
import { SidebarLayoutButton } from '../../components/Buttons/SidebarLayoutButton/SidebarLayoutButton';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';

export interface IExtendWidget {
  isEditor: boolean;
  isMobile: boolean;
  lng: string;
  isFullscreen: boolean;
  experiments: any;
}

class SidebarComponent extends React.Component<
  IChallengePageProps & IWixSDKContext & IExtendWidget & InjectedBiLoggerProps,
  IChallengePageState
> {
  static displayName = 'ChallengePage';
  public readonly pageName = 'challenge-page';

  private readonly wrapperRef = null;
  private readonly stepViewRef = null;

  constructor(
    props: IChallengePageProps &
      IWixSDKContext &
      IExtendWidget &
      InjectedBiLoggerProps,
  ) {
    super(props);

    const { participant } = this.props;

    this.state = {
      isError: null,
      wixAdvertisementBannerHeight: undefined,
      currentStep: null,
      chosenSectionTileId: null,
      isShareModalOpened: false,
      isFeedbackModalOpened: false,
      selectedPaymentOption: null,
      isParticipantCompletedChallengeState:
        isParticipantCompletedChallenge(participant),
      sharedCurrentDateForWeeksSelector: null,
      mobileScreen: 'list',
      isCurrentStateWasResolved: false,
      savedQuestionnaireData: null,
    };

    this.wrapperRef = React.createRef();
    this.stepViewRef = React.createRef();
  }

  static getDerivedStateFromProps = (
    props: IChallengePageProps & { isEditor: boolean },
  ): Partial<IChallengePageState> => {
    if (props.isEditor) {
      const currentStep = props?.participantSteps?.steps?.[0];
      return {
        currentStep,
      };
    }
  };

  async componentDidMount() {
    this.setState({
      wixAdvertisementBannerHeight: getWixAdvertisementBannerHeight(),
    });
  }

  componentDidUpdate(prevProps): void {
    if (
      prevProps.isResolveStepRequestInProgress &&
      !this.props.isResolveStepRequestInProgress
    ) {
      if (!this.props.resolveStepError) {
        this.props.showToast(
          this.props.t(
            !this.state.isCurrentStateWasResolved
              ? 'toast.step-completed'
              : 'toast.step-feedback-updated',
          ),
        );
      } else {
        this.props.showToast(this.props.t('toast.step-completed-error'), {
          type: ToastType.error,
        });
      }
    }
  }

  sendBiButtonClick(buttonName: ButtonNames) {
    void this.props.bi.report(
      memberWebAppButtonClickV2({
        buttonName,
      }),
    );
  }

  setStep(currentStep: V1ParticipantStep, preventScrolling?: boolean) {
    this.setState(
      {
        currentStep,
      },
      () => {
        if (!preventScrolling) {
          document
            .querySelector(`li[data-id="${currentStep?.id}"]`)
            ?.scrollIntoView({
              behavior: 'smooth',
            });
        }
      },
    );
  }

  onSectionChosen = (chosenSectionTileId: string) => {
    this.scrollToContent();
    this.setState({
      currentStep: null,
      mobileScreen: 'view',
      chosenSectionTileId,
    });
  };

  onStepChosen = (step: V1ParticipantStep): void => {
    this.scrollToContent();

    this.sendBiButtonClick(
      step ? ButtonNames.StepTileSelection : ButtonNames.OverviewTileSelection,
    );

    this.setState({
      currentStep: step,
      mobileScreen: 'view',
      chosenSectionTileId: null,
    });
  };

  async onNextEntity(nextEntity: INextEntity): Promise<void> {
    switch (nextEntity.type) {
      case ChallengeEntityType.STEP:
        this.onStepChosen(nextEntity.step);
        this.sendBiButtonClick(ButtonNames.NextStep);
        break;
      case ChallengeEntityType.SECTION:
        this.onSectionChosen(nextEntity.section?.id);
        this.sendBiButtonClick(ButtonNames.NextSection);
        break;
      default:
        return null;
    }
  }

  private scrollToContent() {
    const { isMobile } = this.props;
    if (
      this.props.experiments.enabled(
        Challenges.enableNewSidebarScrollingBehaviour,
      )
    ) {
      setElementToViewport(this.stepViewRef.current);
    } else {
      this.stepViewRef.current.scrollTop = 0;
    }
    if (isMobile) {
      window?.scrollTo?.(0, 0);
    }
  }

  onFeedbackView = async (step: V1ParticipantStep) => {
    this.setState(
      {
        currentStep: step,
        isFeedbackModalOpened: true,
        savedQuestionnaireData: null,
      },
      () => {
        this.sendBiButtonClick(ButtonNames.SeeFeedback);
      },
    );
  };

  onStepResolve = async (step: V1ParticipantStep) => {
    this.setState(
      {
        currentStep: step,
        savedQuestionnaireData: null,
      },
      async () => {
        this.sendBiButtonClick(ButtonNames.StepComplete);

        if (utils.isFeedbackFormRequired(step)) {
          this.setState({
            isFeedbackModalOpened: true,
          });
        } else {
          this.resolveStep();
        }
      },
    );
  };

  resolveStep() {
    const {
      listParticipantSections,
      participantSteps: { steps = [] } = {},
      updateParticipantStepStatus,
      resolveStep,
    } = this.props;

    this.setState(
      {
        isFeedbackModalOpened: false,
      },
      async () => {
        const stepForView = this.state.currentStep;

        if (stepForView?.id) {
          const {
            isStepResolved: isCurrentStepResolved,
            currentStepAfterUpdate,
          } = utils.getResolveStepData(
            stepForView,
            this.state.savedQuestionnaireData,
          );

          this.setState(
            {
              currentStep: currentStepAfterUpdate,
              isCurrentStateWasResolved: isStepResolved(stepForView),
              savedQuestionnaireData: null,
            },
            async () => {
              /*
                Optimistic Update.
                Change the status of current step and then run resolve request.
                Otherwise the current step can be changed faster then request finished.
               */
              updateParticipantStepStatus(
                listParticipantSections,
                steps,
                currentStepAfterUpdate?.id,
                currentStepAfterUpdate?.transitions,
                currentStepAfterUpdate?.feedback,
              );

              await resolveStep(
                stepForView.id,
                currentStepAfterUpdate?.feedback,
                isCurrentStepResolved,
              );
            },
          );
        } else {
          console.error("Can't find step for resolve.");
        }
      },
    );
  }

  goToMobileList = () => {
    const { isMobile } = this.props;
    if (isMobile) {
      this.setState(
        {
          mobileScreen: 'list',
        },
        () => {
          // window?.scrollTo?.(0, 0);
        },
      );
    }
  };

  render() {
    const {
      challengeData,
      isLoading,
      isFullWidthLayout,
      experiments,
      settings,
      isMobile,
    } = this.props;

    const { mobileScreen } = this.state;

    return challengeData && challengeData.challenge ? (
      isLoading ? (
        <div className={classes.fullPageSpinner}>
          <Spinner data-hook={`${this.pageName}-spinner`} />
        </div>
      ) : (
        <main
          data-hook={this.pageName}
          className={st(classes.root, {
            mobile: isMobile,
            mobileScreen,
            newScrolling: experiments.enabled(
              Challenges.enableNewSidebarScrollingBehaviour,
            ),
            fullWidthLayout: isFullWidthLayout,
            withoutSpace: settings.sidebarLayoutSpace === 0,
            align: settings.sidebarLayoutTextAlignment,
            fullscreen: this.props.isFullscreen,
          })}
        >
          {this.renderWithChallenge()}
        </main>
      )
    ) : null;
  }

  renderWithChallenge() {
    const { challenge, badgesData } = this.props.challengeData;
    const isSPC = isSelfPaced(challenge);
    const { t } = this.props;
    const isSections = isSPC && this.props.listParticipantSections?.length;
    const isSectionActive = this.state.chosenSectionTileId;
    const isStepActive = this.state.currentStep?.id;
    const isFinished =
      this.state.isParticipantCompletedChallengeState &&
      this.props.experiments.enabled(Challenges.enableHappyScreens);
    const stickyPositionTop =
      this.state.wixAdvertisementBannerHeight &&
      this.props.experiments.enabled(
        Challenges.enableNewSidebarScrollingBehaviour,
      )
        ? `${this.state.wixAdvertisementBannerHeight}px`
        : undefined;

    const isOverviewActive = !isSectionActive && !isStepActive;
    const flatStepsList = getFlatStepsList(this.props);
    const progressPercentage = utils.getStepsResolvedValue(flatStepsList);

    return (
      <>
        <ChallengeNotification // no notifications now, saved for future cases
          challengeJoinRestrictions={[]}
          type="Sidebar"
        />

        <div className={classes.wrapper} ref={this.wrapperRef}>
          <div
            style={{
              top: stickyPositionTop,
            }}
            className={`${classes.column} ${classes.sidebar}`}
          >
            <div className={classes.summary}>
              <ChallengeSummary />
            </div>
            <div className={classes.stepsListBox}>
              <OverviewSidebar
                onStepChosen={this.onStepChosen}
                isActive={
                  !this.state.currentStep && !this.state.chosenSectionTileId
                }
              />

              {isSections ? (
                <SectionsListAsTiles
                  chosenSectionTileId={this.state.chosenSectionTileId}
                  currentStepId={this.state.currentStep?.id}
                  onStepChosen={this.onStepChosen}
                  onSectionChosen={this.onSectionChosen}
                />
              ) : (
                this.renderStepsList()
              )}
            </div>
          </div>
          <div
            style={{ width: `${this.props.settings.sidebarLayoutSpace}px` }}
          />
          <div className={`${classes.column} ${classes.contentBody}`}>
            <div className={classes.stepView}>
              <div className={classes.stepViewContent} ref={this.stepViewRef}>
                {isSectionActive ? (
                  <SidebarSectionView
                    goToCurrentStep={() => {
                      const step = getFirstAvailableStepFromSection(
                        this.props.listParticipantSections,
                      );

                      if (step) {
                        this.setState({
                          currentStep: step,
                          chosenSectionTileId: null,
                        });
                      }
                    }}
                    currentSectionId={this.state.chosenSectionTileId}
                  />
                ) : isStepActive &&
                  this.props.shownTab !== ListLayoutSelectedTab.Overview ? (
                  this.renderStepView()
                ) : (
                  <ChallengeOverview
                    type="Sidebar"
                    challenge={challenge}
                    isGroupInstalled={this.props.isGroupsInstalled}
                    prefix={this.renderBreadcrumbs()}
                    badges={badgesData?.badges ?? []}
                  />
                )}
              </div>
            </div>
            {isOverviewActive &&
              progressPercentage !== 100 &&
              this.props.experiments.enabled(
                Challenges.enableOverviewStartButton,
              ) && (
                <SidebarControlsContainer>
                  <SidebarLayoutButton
                    onClick={() => {
                      this.setStep(getNextStepToComplete(flatStepsList), true);
                      this.scrollToContent();
                      this.sendBiButtonClick(ButtonNames.OverviewStartButton);
                    }}
                  >
                    {progressPercentage === 0
                      ? t('challenge.page.overview.start-btn')
                      : t('challenge.page.overview.current-step')}
                  </SidebarLayoutButton>
                </SidebarControlsContainer>
              )}
            {isSectionActive || isStepActive || isFinished ? (
              <>
                {isFinished ? <FinishedNotification /> : null}
                {isSectionActive && !isFinished ? (
                  <SectionControls
                    currentSectionId={this.state.chosenSectionTileId}
                    steps={this.props.participantSteps}
                    sections={this.props.listParticipantSections}
                    onNextEntity={(entity) => this.onNextEntity(entity)}
                    onStepChosen={(step) => this.onStepChosen(step)}
                  />
                ) : null}
                {isStepActive && !isFinished ? (
                  <StepControls
                    currentStep={this.state.currentStep}
                    isCompletedChallenge={
                      this.state.isParticipantCompletedChallengeState
                    }
                    steps={this.props.participantSteps}
                    sections={this.props.listParticipantSections}
                    buttonState={this.props.buttonState}
                    onStepResolve={(step) => this.onStepResolve(step)}
                    onNextEntity={(entity) => this.onNextEntity(entity)}
                    onFeedbackView={(step) => this.onFeedbackView(step)}
                    ShareButton={() => this.renderShareButton()}
                  />
                ) : null}
              </>
            ) : null}
          </div>

          {this.state.currentStep ? (
            <ShareModal
              step={this.state.currentStep}
              isOpened={this.state.isShareModalOpened}
              onClose={() => {
                this.setState({
                  isShareModalOpened: false,
                });
              }}
            />
          ) : null}

          {this.state.currentStep ? (
            <ChallengeQuestionnaire
              isOpened={this.state.isFeedbackModalOpened}
              currentStep={this.state.currentStep}
              savedQuestionnaireData={this.state.savedQuestionnaireData}
              onSaveIntermediateData={(type, data) => {
                this.setState({
                  savedQuestionnaireData: {
                    ...this.state.savedQuestionnaireData,
                    ...{
                      [type]: data || {},
                    },
                  },
                });
              }}
              onResolve={() => {
                this.resolveStep();
              }}
              onCancel={() => {
                this.setState({
                  isFeedbackModalOpened: false,
                  savedQuestionnaireData: null,
                });
              }}
            />
          ) : null}
        </div>
      </>
    );
  }

  renderStepsList() {
    const {
      challengeData: { challenge },
      isParticipantStepsLoading,
    } = this.props;
    const { currentStep } = this.state;

    if (isParticipantStepsLoading) {
      return null;
    }

    return (
      <StepsListAsTiles
        steps={this.props.participantSteps?.steps}
        isSPC={isSelfPaced(challenge)}
        currentStepId={currentStep?.id}
        onStepChosen={this.onStepChosen}
      />
    );
  }

  renderStepView() {
    const {
      challengeData: { challenge },
      isListParticipantSectionsRequestInProgress,
      listParticipantSections,
      isParticipantStepsLoading,
      participantSteps,
    } = this.props;

    return (
      <SideBarStepView
        participantSteps={participantSteps}
        listParticipantSections={listParticipantSections}
        challenge={challenge}
        isListParticipantSectionsRequestInProgress={
          isListParticipantSectionsRequestInProgress
        }
        goToCurrentStep={() => {
          this.setStep(
            utils.getFirstAvailableStep(getFlatStepsList(this.props)),
          );
        }}
        isParticipantStepsLoading={isParticipantStepsLoading}
        currentStep={this.state.currentStep}
        Breadcrumbs={() => this.renderBreadcrumbs()}
        StepShareButton={() => this.renderShareButton()}
        pageRef={this.props.pageRef}
      />
    );
  }

  renderBreadcrumbs() {
    const { listParticipantSections } = this.props;
    const { currentStep } = this.state;

    return (
      <StepViewBreadcrumbs
        sections={listParticipantSections}
        currentStep={currentStep}
        goToMobileList={this.goToMobileList}
        onSectionChosen={this.onSectionChosen}
      />
    );
  }

  renderShareButton() {
    const {
      t,
      location: { url },
      isResolveStepRequestInProgress,
    } = this.props;
    const isResolved = isStepResolved(this.state.currentStep);
    const isShareExpEnabled = this.props.experiments.enabled(
      Challenges.showStepShare,
    );

    return isResolved && isShareExpEnabled ? (
      <ShareButton
        className={classes.shareButton}
        shareData={{
          title: t('social-share.title'),
          url,
        }}
        onClick={(sharePromise) => {
          if (!sharePromise && !isResolveStepRequestInProgress) {
            this.setState(
              {
                isShareModalOpened: true,
              },
              () => {
                this.sendBiButtonClick(ButtonNames.ShareCompletedStep);
              },
            );
          }
        }}
        withIcon
      />
    ) : null;
  }
}

export default withExperiments<any>(withBi(SidebarComponent));
