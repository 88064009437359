import React from 'react';

import { IQuestionnaireType } from '../interfaces';
import {
  ChoiceRightness,
  FeedbackItemSettings,
} from '@wix/ambassador-challenge-service-web/types';
import { useTranslation } from 'react-i18next';

import {
  ChoiceQuestionnaireField,
  MediaQuestionnaireField,
  NumericQuestionnaireField,
  QuantityQuestionnaireField,
  TextQuestionnaireField,
} from './fields';
import utils from '../utils';

import { classes, st } from './index.st.css';

export const QuestionnaireField: React.FC<{
  type: IQuestionnaireType;
  field: FeedbackItemSettings;
  currentValue: any;
  isCheckAnswersState: boolean;
  onChange(id: string, value: any): void;
  onLoadingChange(isLoading: boolean): void;
}> = ({
  type,
  field,
  currentValue,
  isCheckAnswersState,
  onChange,
  onLoadingChange,
}) => {
  const { t } = useTranslation();
  const fieldType = utils.getFieldType(field);

  let content = null;

  switch (fieldType) {
    case 'text':
      content = (
        <TextQuestionnaireField
          type={type}
          field={field}
          currentValue={currentValue}
          onChange={onChange}
        />
      );

      break;
    case 'quantity':
      content = (
        <QuantityQuestionnaireField
          type={type}
          field={field}
          currentValue={currentValue}
          onChange={onChange}
        />
      );

      break;
    case 'media':
      content = (
        <MediaQuestionnaireField
          type={type}
          field={field}
          currentValue={currentValue}
          onChange={onChange}
          onLoadingChange={onLoadingChange}
        />
      );

      break;
    case 'multipleChoice':
    case 'multiSelect':
      content = (
        <ChoiceQuestionnaireField
          type={type}
          field={field}
          currentValue={currentValue}
          isCheckAnswersState={isCheckAnswersState}
          onChange={onChange}
        />
      );

      break;
    case 'numeric':
      content = (
        <NumericQuestionnaireField
          type={type}
          field={field}
          currentValue={currentValue}
          onChange={onChange}
        />
      );

      break;
    default:
  }

  return (
    <div
      className={st(classes.root)}
      data-question-id={field.id}
      key={`field-${field.id}`}
    >
      <label
        id={`field-${field.id || field?.question?.replace(/\s/g, '-')}`}
        className={classes.title}
      >
        {utils.getFieldLabel(t, field)}
      </label>

      {type === IQuestionnaireType.QUIZ ? (
        <p className={classes.description}>
          {t('questionnaire.choice.description', {
            count: (
              field.type.multipleChoice || field.type.multiSelect
            )?.choices?.filter(
              (choice) => choice.rightness === ChoiceRightness.RIGHT,
            ).length,
          })}
        </p>
      ) : (
        ''
      )}

      {content}
    </div>
  );
};
QuestionnaireField.displayName = 'QuestionnaireField';
