import React from 'react';

import {
  imageDataToMediaUrl,
  IImageData,
} from '@wix/challenges-web-library/dist/src';
import { getVideoUrl } from '@wix/challenges-web-library/dist/src/helpers/video';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as RemoveIcon } from '../../assets/icons/remove.svg';

import { st, classes } from './FileUpload.st.css';
import { Spinner } from '../Spinner';
import { getMediaCreds, uploadFileToMP } from '../../services/mediaUpload';
import { IOutputFile } from './types';

const getDuration = (durationInMs: number): string => {
  const minutes = Math.floor(durationInMs / 60);
  const seconds = (durationInMs % 60).toFixed(0);

  return `${minutes}:${+seconds < 10 ? '0' : ''}${seconds}`;
};

export interface IFileProps extends IImageData {
  mediaType: 'image' | 'video';
}

export interface IFileUploadProps {
  id?: string;
  files: IFileProps[];
  instance: string;
  onUpload(file: IOutputFile): void;
  onChangeLoading?(loading: boolean): void;
  error?: boolean;
  disabled?: boolean;
  maxItems?: number;
  onRemove?(file: IFileProps): void;
  challengeId: string;
  participantId: string;
  ariaLabel?: string;
}

export interface IFileUploadState {
  itemsLoading: number;
}

export class FileUpload extends React.PureComponent<
  IFileUploadProps,
  IFileUploadState
> {
  private readonly $input = React.createRef<HTMLInputElement>();
  private readonly $videos = React.createRef<HTMLVideoElement[]>();

  static defaultProps: Partial<IFileUploadProps> = {
    id: '0000',
    maxItems: 10,
  };

  state = {
    itemsLoading: 0,
  };

  handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = Array.from(event.target.files);
    const filesCount = this.props.files.length + files.length;

    const validatedFiles =
      filesCount > this.props.maxItems
        ? files.slice(0, this.props.maxItems - this.props.files.length)
        : files;

    try {
      if (this.props.onChangeLoading) {
        this.props.onChangeLoading(true);
      }

      await Promise.all(
        validatedFiles.map(async (file) => {
          this.setState({ itemsLoading: ++this.state.itemsLoading });
          await this.uploadMedia(file);
        }),
      );
    } catch (e) {
      // TODO:show error(?)
      console.log(e);
    } finally {
      this.$input.current.value = null;

      if (this.props.onChangeLoading) {
        this.props.onChangeLoading(false);
      }
    }
  };

  async uploadMedia(file: File) {
    const { challengeId, participantId, instance } = this.props;
    const { uploadUrl, uploadToken } = await getMediaCreds(
      file.name,
      challengeId,
      participantId,
      instance,
    );

    const responseMedia = await uploadFileToMP(file, uploadUrl, uploadToken);

    this.setState({ itemsLoading: --this.state.itemsLoading });

    return this.props.onUpload(responseMedia[0]);
  }

  onFileAddClick = (e) => {
    e.preventDefault();
    this.$input?.current.click();
  };

  render() {
    const { id, error, disabled, maxItems, files, ariaLabel } = this.props;
    const { itemsLoading } = this.state;
    const isDisabled = disabled || files.length >= maxItems || !!itemsLoading;
    const label = 'Add files';

    return (
      <div className={st(classes.root, { error, disabled: isDisabled })}>
        <label
          aria-disabled={isDisabled}
          htmlFor={`file-upload-${id}`}
          className={`${classes.item} ${classes.uploadButton}`}
        >
          {label}
          <button
            aria-label={`${ariaLabel} ${label}`}
            className={classes.htmlButton}
            aria-controls={`file-upload-${id}`}
            onClick={this.onFileAddClick}
          >
            <PlusIcon />
          </button>
        </label>

        {!!itemsLoading &&
          new Array(itemsLoading).fill('').map((_i, idx) => (
            <div
              key={`file-loader-${idx}`}
              className={`${classes.item} ${classes.loader}`}
            >
              <Spinner role="element" />
            </div>
          ))}

        {files.map((file, idx) => (
          <div
            tabIndex={0}
            key={`media-${file.url}`}
            className={`${classes.item} ${classes.mediaItem} ${classes.itemImage}`}
          >
            {file.mediaType === 'video' ? (
              <figure className={classes.media}>
                <video
                  ref={(el) => {
                    this.$videos[idx] = el;
                  }}
                  onLoadedMetadata={() => {
                    this.forceUpdate();
                  }}
                  autoPlay={false}
                  controls={false}
                  className={classes.video}
                  src={getVideoUrl(file.url)}
                />

                {!isNaN(this.$videos[idx]?.duration) && (
                  <span className={classes.mediaDuration}>
                    {getDuration(this.$videos[idx]?.duration)}
                  </span>
                )}
              </figure>
            ) : (
              <img
                className={classes.media}
                src={imageDataToMediaUrl({
                  ...file,
                  width: 200,
                  height: 200,
                })}
                alt="uploaded photo"
              />
            )}

            <button
              aria-label="Remove file"
              className={classes.removeFile}
              onClick={() => {
                this.props.onRemove(file);
              }}
            >
              <RemoveIcon />
            </button>
          </div>
        ))}

        <input
          id={`file-upload-${id}`}
          hidden={true}
          onChange={this.handleFileInputChange}
          type="file"
          ref={this.$input}
          multiple={true}
          accept="video/*,image/*"
        />
      </div>
    );
  }
}
