import React from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import {
  PRIORITY as ButtonPRIORITY,
  SIZE as ButtonSIZE,
} from 'wix-ui-tpa/Button';
import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';
import { Challenges } from '../../../../../editor/types/Experiments';
import utils, { getFlatStepsList } from '../../views/utils';
import { useChallengeData } from '../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';

import { IControl } from './types';
import { isSectionLocked } from '../../../../../selectors/sections';
import { SidebarLayoutButton } from '../Buttons/SidebarLayoutButton/SidebarLayoutButton';
import { SidebarControlsContainer } from '../SidebarControlsContainer/SidebarControlsContainer';

export interface ISectionControls extends IControl {
  currentSectionId: string;
  onStepChosen(firstSectionStep: V1ParticipantStep): void;
}

export const SectionControls: React.FC<ISectionControls> = (props) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { isMobile } = useEnvironment();
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const chosenSection = props.sections.find(
    (s) => s.id === props.currentSectionId,
  );

  const isHideSection =
    experiments.enabled(Challenges.enableDripContent) &&
    isSectionLocked(chosenSection);

  if (isHideSection) {
    return null;
  }

  const flatSteps = getFlatStepsList({
    listParticipantSections: props.sections,
    participantSteps: props.steps,
  });

  const firstSectionStep = chosenSection?.steps?.[0];

  const isFirstSectionStepHidden =
    firstSectionStep &&
    utils.isStepHidden(
      challenge,
      flatSteps,
      firstSectionStep,
      experiments.enabled(Challenges.isHiddenStepsEnabled),
    );

  const nextEntity = utils.getNextEntity(firstSectionStep?.id, {
    listParticipantSections: props.sections,
    participantSteps: props.steps,
    currentSection: chosenSection,
  });

  if (isFirstSectionStepHidden) {
    return null;
  }

  return (
    <SidebarControlsContainer>
      <SidebarLayoutButton
        fullWidth={isMobile}
        priority={ButtonPRIORITY.primary}
        size={isMobile ? ButtonSIZE.small : ButtonSIZE.medium}
        onClick={async () => {
          if (firstSectionStep) {
            props.onStepChosen(firstSectionStep);
          } else {
            await props.onNextEntity(nextEntity);
          }
        }}
      >
        {t('challenge.page.steps.continue')}
      </SidebarLayoutButton>
    </SidebarControlsContainer>
  );
};
