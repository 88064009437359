import React from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { OverviewIcon } from '../../../../../components-shared/OverviewIcon';

import { st, classes } from '../StepsListAsTiles/StepsListAsTiles.st.css';

import challengeSettings from '../../../settingsParams';

export interface IOverviewSidebar {
  isActive: boolean;
  onStepChosen: Function;
}

export const OverviewSidebar: React.FC<IOverviewSidebar> = (props) => {
  const { t } = useTranslation();
  const settings = useSettings();

  return (
    <div
      className={st(classes.root, {
        align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
      })}
    >
      <ul className={classes.stepsList}>
        <li
          className={`${classes.stepItem} ${classes.overviewItem} ${
            props.isActive ? classes.stepItemActive : ''
          }`}
          onClick={() => {
            props.onStepChosen(null);
          }}
        >
          <button className={classes.stepLink} onClick={() => {}}>
            <OverviewIcon />
            <div className={classes.gapBetweenTitleAndIcon} />
            <p className={`${classes.stepItemTitle}`}>
              {t('live.challenges-page-sidebar.overview-title')}
            </p>
          </button>
        </li>
      </ul>
    </div>
  );
};

OverviewSidebar.displayName = 'OverviewSidebarItem';
