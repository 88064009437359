import React from 'react';
import { Popover } from 'wix-ui-core/dist/src/components/popover';
import { ActionsMenuLayout } from 'wix-ui-tpa/ActionsMenuLayout';
import { Ellipses } from '../icons/Ellipses';
import { st, classes } from './Dots.st.css';

export interface IDotsProps {
  popoverPlacement?: 'bottom-end' | 'bottom-start';
  onOpenMenu?(): void;
  menuItems: {
    title: string;
    callback(): void;
    isHidden?: boolean;
  }[];
}

interface IDotsState {
  isShown: boolean;
}

export class Dots extends React.Component<IDotsProps, IDotsState> {
  static displayName = 'Dots';

  constructor(props: IDotsProps) {
    super(props);

    this.state = {
      isShown: false,
    };
  }

  onClick = () => {
    this.setState(
      (state) => ({
        isShown: !state.isShown,
      }),
      () => {
        if (this.state.isShown && this.props.onOpenMenu) {
          this.props.onOpenMenu();
        }
      },
    );
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Esc' || e.key === 'Escape') {
      this.setState({ isShown: false });
    }
  };

  onClickOutside = () => {
    this.setState({
      isShown: false,
    });
  };

  render() {
    return (
      <Popover
        className={classes.popover}
        shown={this.state.isShown}
        dynamicWidth={true}
        maxWidth={250}
        onClickOutside={this.onClickOutside}
        placement={this.props.popoverPlacement || 'bottom-end'}
        showArrow={false}
      >
        <Popover.Element>
          <button
            aria-label="Challenge actions"
            onClick={this.onClick}
            className={st(classes.root)}
          >
            <Ellipses />
          </button>
        </Popover.Element>
        <Popover.Content>
          <div onKeyDown={this.onKeyDown}>
            <ActionsMenuLayout>
              {this.props.menuItems
                .filter((item) => !item.isHidden)
                .map((item, key) => {
                  return (
                    <ActionsMenuLayout.Item
                      key={key}
                      content={item.title}
                      onClick={() => {
                        this.setState(
                          {
                            isShown: false,
                          },
                          () => {
                            item.callback();
                          },
                        );
                      }}
                    />
                  );
                })}
            </ActionsMenuLayout>
          </div>
        </Popover.Content>
      </Popover>
    );
  }
}
