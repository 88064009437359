import React from 'react';

import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import useLocales from '../../../../../services/hooks/useLocales';
import { getRightDateFromBackend } from '../../../../../selectors/dates';
import challengeSettings from '../../../settingsParams';
import format from 'date-fns/format';

import groupBy from 'lodash/groupBy';

import { IStepViewProps, StepView } from '../StepView';

import { st, classes } from './StepsList.st.css';
import { isStepAvailableForComplete } from '../../views/utils';

export interface IStepsListProps
  extends Omit<IStepViewProps, 'step' | 'isStepOpened'> {
  className?: string;
  stepViewClassName?: string;
  renderType?: 'AccordionTitle' | 'AccordionFull';
  steps: V1ParticipantStep[];
  isSPC: boolean;
  stepIdForceOpened?: string;
}

const FlatStepsList: React.FC<IStepsListProps> = (props: IStepsListProps) => {
  const {
    className,
    stepViewClassName,
    steps = [],
    isSPC,
    stepIdForceOpened,
    ...rest
  } = props;
  let isFirstRunningStepWasOpened = false;
  const { t } = useTranslation();

  return (
    <div role="tablist" aria-label={t('challenge.steps')}>
      {steps.map((step: V1ParticipantStep, ind: number) => {
        const isStepOpened =
          isStepAvailableForComplete(step) && !isFirstRunningStepWasOpened;

        if (isStepOpened) {
          isFirstRunningStepWasOpened = true;
        }

        return (
          <StepView
            key={`step-${ind}`}
            className={stepViewClassName}
            step={step}
            isStepOpened={isStepOpened}
            isStepForceOpened={
              stepIdForceOpened && stepIdForceOpened === step.id
            }
            {...rest}
          />
        );
      })}
    </div>
  );
};
FlatStepsList.displayName = 'FlatStepsList';

const ScheduledStepsList: React.FunctionComponent<IStepsListProps> = (
  props: IStepsListProps,
) => {
  const {
    className,
    stepViewClassName,
    steps = [],
    isSPC,
    stepIdForceOpened,
    ...rest
  } = props;
  const { language } = useEnvironment();
  const stepsByStartDate = groupBy(steps, (step: V1ParticipantStep) => {
    return step.dateFrame.start;
  });
  let isFirstRunningStepWasOpened = false;

  const { locales, isLocalesLoading } = useLocales();

  return (
    <>
      {Object.keys(stepsByStartDate).map((startDate) => (
        <>
          <h2 className={classes.title}>
            {!isLocalesLoading
              ? format(getRightDateFromBackend(startDate), 'MMMM dd', {
                  locale: locales[language],
                })
              : ''}
          </h2>
          <div role="tablist">
            {stepsByStartDate[startDate].map(
              (step: V1ParticipantStep, ind: number) => {
                const isStepOpened =
                  isStepAvailableForComplete(step) &&
                  !isFirstRunningStepWasOpened;

                if (isStepOpened) {
                  isFirstRunningStepWasOpened = true;
                }

                return (
                  <StepView
                    key={`step-${ind}`}
                    className={stepViewClassName}
                    step={step}
                    isStepOpened={isStepOpened}
                    isStepForceOpened={
                      stepIdForceOpened && stepIdForceOpened === step.id
                    }
                    {...rest}
                  />
                );
              },
            )}
          </div>
        </>
      ))}
    </>
  );
};
ScheduledStepsList.displayName = 'ScheduledStepsList';

export const StepsList: React.FunctionComponent<IStepsListProps> = (
  props: IStepsListProps,
) => {
  const { isSPC, className } = props;
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  return (
    <div
      className={`${st(classes.root, {
        mobile: isMobile,
        contentTextAlignment: settings.get(
          challengeSettings.listLayoutContentAlignment,
        ),
      })} ${className}`}
    >
      {isSPC ? <FlatStepsList {...props} /> : <ScheduledStepsList {...props} />}
    </div>
  );
};
StepsList.displayName = 'StepsList';
StepsList.defaultProps = {
  className: '',
  stepViewClassName: '',
  renderType: 'AccordionFull',
};
