import { Group } from '@wix/ambassador-social-groups-web/types';

const GROUPS_URL = '/_api/social-groups/v2/groups';
// const GROUPS_PROXY_URL = 'http://localhost:3100/groups-proxy';

const groups: { [key: string]: Group } = {};

export const getGroup = async (
  instance: string,
  origin: string,
  groupId: string,
): Promise<Group> => {
  const group = groups[groupId];

  if (group) {
    return group;
  }

  if (!instance || !groupId) {
    return;
  }

  try {
    groups[groupId] = (
      await (
        await fetch(`${origin}${GROUPS_URL}/${groupId}`, {
          method: 'GET',
          headers: {
            Authorization: instance,
          },
        })
      ).json()
    )?.group;

    return groups[groupId];
  } catch (err) {
    console.error('Failed fetching Group:', err);
  }
};
