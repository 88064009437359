const getApUrl = (
  challengeId: string,
  participantId: string,
  fileName: string,
): string => {
  return `/_api/challenge-service-web/api/v1/challenges/${challengeId}/participants/${participantId}/media/upload_info?file_name=${fileName}`;
};

const MEDIA_TYPES = {
  image: 'picture',
  video: 'video',
};

export const getMediaCreds = async (
  fileName: string,
  challengeId: string,
  participantId: string,
  instance: string,
): Promise<{
  uploadToken: string;
  uploadUrl: string;
}> => {
  return fetch(getApUrl(challengeId, participantId, fileName), {
    headers: {
      Authorization: instance,
    },
  }).then((r) => {
    return r.json();
  });
};

export const uploadFileToMP = async (
  file: File,
  uploadUrl: string,
  uploadToken: string,
): Promise<any> => {
  const fileType = file.type.split('/')[0];

  const mediaType = MEDIA_TYPES[fileType];
  const formData = new FormData();

  formData.append('upload_token', uploadToken);
  formData.append('media_type', mediaType);
  formData.append('file', file);
  formData.append('parent_folder_id', '/');

  return fetch(uploadUrl, {
    method: 'POST',
    body: formData,
  }).then((r) => {
    return r.json();
  });
};
