import React from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import {
  ParticipantSection,
  V1ParticipantStep,
} from '@wix/ambassador-challenge-service-web/types';

import { Back } from '../icons/Back';
import { Breadcrumbs } from '../../../../../components-shared/Breadcrumbs';

import { classes, st } from './StepViewBreadcrumbs.st.css';
import utils from '../../views/utils';

export interface IStepViewBreadcrumbsProps {
  sections: ParticipantSection[];
  currentStep: V1ParticipantStep;
  goToMobileList(): void;
  onSectionChosen(id: string): void;
}

export const StepViewBreadcrumbs: React.FunctionComponent<IStepViewBreadcrumbsProps> =
  ({
    sections,
    currentStep,
    goToMobileList,
    onSectionChosen,
  }: IStepViewBreadcrumbsProps) => {
    const { t } = useTranslation();
    const { isMobile } = useEnvironment();
    const settings = useSettings();
    const alignment = utils.getContentAlignByType('Sidebar', settings);

    const currentSection = (sections || []).find((section) => {
      return section?.steps?.some((step) => step?.id === currentStep?.id);
    });

    return (
      <div
        className={st(classes.root, {
          mobile: isMobile,
          alignment,
        })}
      >
        {isMobile ? (
          <p className={classes.mobileBackButton} onClick={goToMobileList}>
            <Back />
            <span>{t('live.challenges-page-sidebar.mobile-back-button')}</span>
          </p>
        ) : currentSection?.id ? (
          <Breadcrumbs className={classes.breadcrumbs}>
            <Breadcrumbs.Link
              text={currentSection.source?.settings?.description?.title}
              onClick={() => onSectionChosen(currentSection.id)}
            />
            <Breadcrumbs.Separator />
            <Breadcrumbs.Link
              text={currentStep?.source?.settings?.general?.description?.title}
            />
          </Breadcrumbs>
        ) : null}
      </div>
    );
  };
StepViewBreadcrumbs.displayName = 'StepViewBreadcrumbs';
